import React, { Fragment, useState } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import { Modal, Tabs, TabsProps } from 'antd';
import './ScanStep.scss';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import ConfirmButton from '../buttons/ConfirmButton';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import { Link } from 'react-router-dom';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import { StartScanningScreen } from '../shared/Hardware/StartScanningScreen';
import SharedScanQRCode from '../shared/Hardware/ScanQRCodeScreen';
import SharedManualScreen from '../shared/Hardware/ManualScreen';
import { ProductGroupScannedStateI } from '../../interfaces/product';
import ProductGroupScanTabsContent from './ScanTabsContent';

const DiscardConfirmContent = () => {
  return (
    <div>
      <h2>Are you sure you want to close this Scanning session?</h2>
      <p>All your currently scanned Hardware will be lost.</p>
    </div>
  );
};

const RenderDescriptionWarningNotice = () => {
  return (
    <Fragment>
      <p>
        Please make sure the Product you’re about to scan have been registered. If it’s not, please go through the
        Product Creation first.
      </p>
      <Link to={`/products/new`}>
        <span>Create Product</span>
        <RightArrowIcon width={16} height={16} />{' '}
      </Link>
    </Fragment>
  );
};

type Props = {
  setCurrentStep: (step: number) => void;
  setScannedData: (_: any) => void;
  handleVerify?: (_: any) => void;
  scannedData: ProductGroupScannedStateI[];
  stopDecodingScanner: boolean;
  finalScannedData?: ProductGroupScannedStateI[];
  setStopDecodingScanner: (_: any) => void;
  handleDiscard: () => void;
};

const ProductGroupScanStep: React.FC<Props> = ({
  scannedData,
  setCurrentStep,
  setScannedData,
  handleVerify,
  stopDecodingScanner,
  setStopDecodingScanner,
  finalScannedData = [],
  handleDiscard,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [isStartScanning, setIsStartScanning] = useState<boolean>(false);

  const validateScanResult = (value: string) => {
    let status = 'success';
    const validateData = [...finalScannedData, ...scannedData];
    if (validateData.length > 0) {
      if (validateData.find((data: ProductGroupScannedStateI) => data.sku === value)) {
        status = 'error';
      }
    }

    setTimeout(() => {
      if (status === 'success') {
        const successModal = modal.success({
          type: 'success',
          title: 'Successfully Scanned!',
          content: 'Product detected and added to scanned list!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <CheckCircleIcon width={16} height={16} color="#5FB670" />,
          width: 345,
        });
        const newData = {
          sku: value,
        } as ProductGroupScannedStateI;
        setScannedData([...scannedData, newData]);

        setTimeout(() => {
          successModal.destroy();
        }, 1000);
      }

      if (status === 'error') {
        const errorModal = modal.error({
          type: 'error',
          title: 'Failed Scanning!',
          content: 'We detected a duplicated scan. Please check again!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <ErrorModalIcon color="#B13939" />,
          width: 345,
        });

        setTimeout(() => {
          errorModal.destroy();
        }, 1000);
      }
    }, 300);
  };

  const handleScanOrManualSubmit = (result: any) => {
    validateScanResult(result.text);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <SharedScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          scanTabsContent={<ProductGroupScanTabsContent scannedData={scannedData} />}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <SharedManualScreen
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanTabsContent={<ProductGroupScanTabsContent scannedData={scannedData} />}
        />
      ),
    },
  ];

  const handleFinishScanning = () => {
    handleVerify?.({});
    setCurrentStep(2);
    setStopDecodingScanner(true);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  return (
    <div className="component-hardware-allocation-scan-step">
      <div className="wrapper">
        {isStartScanning ? (
          <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />
        ) : (
          <StartScanningScreen
            warningDescription={<RenderDescriptionWarningNotice />}
            setIsStartScanning={setIsStartScanning}
          />
        )}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          <ConfirmButton label="Discard" onOk={handleDiscard} content={<DiscardConfirmContent />} type="error" />
          <PrimaryButton label="Finish Scanning" onClick={handleFinishScanning} />
        </div>
      </div>
    </div>
  );
};

export default ProductGroupScanStep;
