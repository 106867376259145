import { BaseResponse } from '../interfaces';
import {
  ProductGroupI,
  ProductGroupScannedStateI,
  ProductGroupVerifyCollectionI,
  ProductGroupsCollectionI,
  ProductHistoryCollectionI,
  ProductHistorySearchParams,
  ProductI,
  ProductSearchParamsI,
  ProductsCollectionI,
} from '../interfaces/product';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetProduct = (sku?: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`products/${sku}`);
  const data: ProductI = response?.data;
  return { data, error, isLoading };
};

export const getProductDetails = async (sku?: string) => {
  const { data: response } = await api.get<BaseResponse<ProductI>>(`products/${sku}`);
  return response?.data;
};

export const getProductsList = async (params?: ProductSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<ProductsCollectionI>>(`products?${handleFilterParams(params)}`);
  return response?.data;
};

export const createProduct = async (values: Partial<ProductI>) => {
  const { data: response }: any = await api.post('products', values);
  return response.data;
};

export const updateProduct = async ({ sku, values }: { sku: string; values: Partial<ProductI> }) => {
  const { data: response }: any = await api.patch(`products/${sku}`, values);
  return response.data;
};

export const deleteProduct = async (id: number) => {
  const { data: response }: any = await api.delete(`products/${id}`);
  return response.data;
};

export const updateProductStatus = async (params: { sku?: string; status: string }) => {
  const { data: response }: any = await api.patch(`products/${params.sku}/status`, { status: params.status });
  return response.data;
};

export const getProductGroupsList = async (params?: ProductSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<ProductGroupsCollectionI>>(
    `product-groups?${handleFilterParams(params)}`,
  );
  return response?.data;
};

export const useGetProductGroup = (code?: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`product-groups/${code}`);
  const data: ProductGroupI = response?.data;
  return { data, error, isLoading };
};

export const doVerifyProductGroup = async (params: { productList: ProductGroupScannedStateI[] }) => {
  const { data: response }: any = await api.post<BaseResponse<ProductGroupVerifyCollectionI>>(
    `product-groups/verify`,
    params,
  );
  return response.data;
};

export const doAddProductGroup = async (values: ProductGroupI) => {
  const { data: response }: any = await api.post('product-groups', values);
  return response.data;
};

export const doUpdateProductGroup = async (params: { code?: string; data: ProductGroupI }) => {
  const { data: response }: any = await api.patch(`product-groups/${params.code}`, params.data);
  return response.data;
};

export const getProductHistory = async (params: { sku?: string; params: ProductHistorySearchParams }) => {
  const { data: response } = await api.get<BaseResponse<ProductHistoryCollectionI>>(
    `products/${params?.sku}/histories?${handleFilterParams(params?.params)}`,
  );
  return response;
};
