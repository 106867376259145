import React from 'react';
import { Button, Divider, Dropdown, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../svgs/MoreActionIcon';
import { DateFormat } from '../../utils/global';
import { AccessControlI } from '../../interfaces';
import Text from '../Text';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';
import { TransferI } from '../../interfaces/transfer';

type Props = {
  access: AccessControlI;
  item: TransferI;
};
const TransferCardItem: React.FC<Props> = ({ item, access }) => {
  const navitate = useNavigate();

  const items = [
    access.isCanRead
      ? {
          key: '1',
          label: (
            <Link to={`/transfers/${item.code}`}>
              <Button className="btn-table-action-primary" type="link">
                View
              </Button>
            </Link>
          ),
        }
      : null,
  ];

  const handleCardClick = (item: TransferI) => {
    navitate(`/transfers/${item.code}`);
  };

  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button className="hardware-card-item card-item" onClick={() => handleCardClick(item)}>
      <a className="btn-more-action" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
        <Dropdown menu={{ items }}>
          <Space>
            <MoreActionIcon />
          </Space>
        </Dropdown>
      </a>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <Text variant="inputLabelSm" className="tw-text-grey-700">
          Transfer Report
        </Text>
        <Text variant="bodyLgB" className="tw-text-primary-800">
          {item?.code}
        </Text>
        <div className="tw-flex tw-flex-col tw-gap-2">
          {renderField({ icon: 'icon-person_outline', value: item?.newOwner?.displayName })}
          {renderField({ icon: 'icon-all_inbox', value: `${item?.hardwareList?.length ?? 0} Hardware` })}
        </div>
        <Divider className="tw-m-0" />
        {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
      </div>
    </button>
  );
};

export default TransferCardItem;
