import React from 'react';
import './HistoryAndAttachment.scss';
import { Tabs, TabsProps } from 'antd';
import AllInboxIcon from '../../svgs/AllInboxIcon';
import PaperClipIcon from '../../svgs/PaperClipIcon';
import ProductAttachments from './Attachments';
import ProductHistory from './History';
import { ProductI } from '../../interfaces/product';
import ProductGroupList from '../ProductGroup/GroupList';
import Icon from '../Icon';

type Props = {
  data?: ProductI;
  id?: string;
  isPlatform?: boolean;
};

const ProductResources: React.FC<Props> = ({ data, id, isPlatform }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span className="tab-title">
          <AllInboxIcon width={20} height={20} /> <span>History</span>
        </span>
      ),
      children: <ProductHistory id={id} />,
    },
    {
      key: '2',
      label: (
        <span className="tab-title">
          <PaperClipIcon /> <span>Attachment</span>
        </span>
      ),
      children: <ProductAttachments attachments={data?.attachments} />,
    },
    {
      key: '3',
      label: (
        <span className="tab-title">
          <Icon name="icon-group_work" size={20} /> <span>Group</span>
        </span>
      ),
      children: <ProductGroupList groups={data?.groups} isPlatform={isPlatform} />,
    },
  ];

  return (
    <div className="product-history-attachment-container  details-container" style={{ marginTop: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default ProductResources;
