import React from 'react';
import { Button, Dropdown, Pagination } from 'antd';
import { PartnerStatus } from '../../pages/Partners/PartnerStatus';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../svgs/MoreActionIcon';
import Divider from '../Divider';
import { SiteI } from '../../interfaces/site';
import { AccessControlI } from '../../interfaces';
import Icon from '../Icon';
import Text from '../Text';

interface Props {
  access: AccessControlI;
  data: SiteI[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const SitesCardList: React.FC<Props> = ({ access, data, pagination, onPaginationChange }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: SiteI) => {
    if (access.isCanRead) navitate(`/sites/${item.id}`);
  };

  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="card-list">
        {data.map((item) => {
          return (
            <button
              key={item.id}
              className="card-item"
              style={{ overflow: 'hidden' }}
              onClick={() => handleCardClick(item)}
            >
              <a
                className="tw-flex tw-w-full tw-justify-between tw-p-0 tw-h-[16px]"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Text variant="inputLabelSm" className="tw-text-primary-800">
                  {item?.partner.legalName ?? 'Not Assigned'}
                </Text>
                <Dropdown
                  menu={{
                    items: [
                      access.isCanRead
                        ? {
                            key: '1',
                            label: (
                              <Link to={`/sites/${item.id}`}>
                                <Button className="btn-table-action-primary" type="link">
                                  View
                                </Button>
                              </Link>
                            ),
                          }
                        : null,
                      access.isCanUpdate
                        ? {
                            key: '2',
                            label: (
                              <Link to={`/sites/${item.id}/edit`}>
                                <Button className="btn-table-action-primary" type="link">
                                  Edit Profile
                                </Button>
                              </Link>
                            ),
                          }
                        : null,
                    ],
                  }}
                >
                  <div>
                    <MoreActionIcon />
                  </div>
                </Dropdown>
              </a>
              <div className="tw-flex tw-justify-between tw-flex-col tw-w-full tw-h-full">
                <div className="tw-flex tw-flex-col tw-gap-3 tw-mb-3">
                  <Text variant="bodyLgB" className="text-capitalize">
                    {item.displayName}
                  </Text>
                  <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                    <Icon name="icon-language" size={12} className="tw-text-grey-600" />
                    <Text variant="inputLabelSm">{item.market}</Text>
                  </div>
                  <div className="tw-flex tw-gap-1 tw-items-start">
                    <Icon name="icon-location_on" size={14} className="tw-text-grey-600 tw-flex-none" />
                    <Text variant="inputLabelSm" className="truncate-3-lines">
                      {' '}
                      {item.addressLine1}, {item.addressLine2}, {item.postalCode}
                    </Text>
                  </div>
                  <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                    <Icon name="icon-corporate_fare" size={12} className="tw-text-grey-600" />
                    <Text variant="inputLabelSm" className="text-capitalize">
                      {item.type}
                    </Text>
                  </div>
                </div>
                <div>
                  <Divider />
                  <div className="tw-pt-3">
                    <PartnerStatus status={item.status} />
                  </div>
                </div>
              </div>
            </button>
          );
        })}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        pageSizeOptions={[5, 10, 20, 50]}
        showSizeChanger
      />
    </section>
  );
};

export default SitesCardList;
