import { Form, message, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { updateRegistrationHistories, useGetRigistrationHistory } from '../../apis/registrationHistories';
import RegistrationHistoryInformation from '../../components/RegistrationHistory/Information';
import DetailsTopSection from '../../components/shared/DetailTopSection';
import { handleAccessControl } from '../../utils/role';
import { CurrentUserI } from '../../interfaces/auth';
import { useMutation, useQueryClient } from 'react-query';
import AddAttachmentForm from '../../components/forms/AddAttachmentForm';
import { ProductAttachmentCategories } from '../../constants';
import RegistrationResources from '../../components/RegistrationHistory/Resources';

const RegistrationDetailsPage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const queryClient = useQueryClient();
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { data: registrationHistory, isLoading } = useGetRigistrationHistory(uuid);
  const currentUser: CurrentUserI = useOutletContext();
  const { mutate } = useMutation(updateRegistrationHistories, {
    onSuccess: async () => {
      message.info('Uploaded attachment!');
      queryClient.invalidateQueries({
        queryKey: [`registration-histories/${uuid}`],
      });
    },
    onError: () => {
      message.error('Adding attachment failed! Please try again');
    },
  });

  const access = handleAccessControl(currentUser, 'registration-histories');

  function handleCancelUploadAttachment() {
    setIsVisibleModalUpload(false);
    form.resetFields(['categoryName', 'file']);
  }
  return (
    <Spin spinning={isLoading}>
      <div id="registration-history-details-container">
        <DetailsTopSection
          label="Registration Report"
          access={access}
          setIsVisibleModalUpload={setIsVisibleModalUpload}
        />
        <RegistrationHistoryInformation registrationHistory={registrationHistory} />
        <RegistrationResources data={registrationHistory} />
        <Modal
          open={isVisibleModalUpload}
          onOk={handleCancelUploadAttachment}
          onCancel={handleCancelUploadAttachment}
          footer={null}
          width={560}
        >
          <AddAttachmentForm
            form={form}
            handleCancel={handleCancelUploadAttachment}
            multiple
            categories={ProductAttachmentCategories}
            update={mutate}
          />
        </Modal>
      </div>
    </Spin>
  );
};

export default RegistrationDetailsPage;
