import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import SiteInformation from '../../../components/Site/Information';
import SiteName from '../../../components/Site/Name';
import { useGetPlatformSite } from '../../../apis/platforms/sites';
import PlatformSiteResources from './Resources';

const PlatformSiteDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: site, isLoading } = useGetPlatformSite(Number(id));

  return (
    <Spin spinning={isLoading}>
      <SiteName site={site} isDisplayActions={false} />
      <SiteInformation site={site} />
      <PlatformSiteResources data={site} />
    </Spin>
  );
};

export default PlatformSiteDetailsPage;
