import React, { ReactNode } from 'react';
import { Button, Dropdown, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../svgs/MoreActionIcon';
import './HardwareCardItem.scss';
import { getTrackingTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';
import { AccessControlI } from '../../interfaces';
import { HardwareItemI } from '../../interfaces/hardware';
import { CommonStatus } from '../CommonStatus';
import Text from '../Text';
import Icon from '../Icon';
import { DateFormat } from '../../utils/global';
import NetworkStatus from '../shared/NetworkStatus';
import Divider from '../Divider';
import { IconName } from '../Icon/iconName';

type Props = {
  item: HardwareItemI;
  access: AccessControlI;
};
const TrackingCardItem: React.FC<Props> = ({ item, access }) => {
  const navitate = useNavigate();

  const items = [
    access.isCanRead
      ? {
          key: '1',
          label: (
            <Link to={`/tracking/${item.serialCode}`}>
              <Button className="btn-table-action-primary" type="link">
                View
              </Button>
            </Link>
          ),
        }
      : null,
  ];

  const handleCardClick = (item: HardwareItemI) => {
    navitate(`/tracking/${item.serialCode}`);
  };

  const renderInfoByType = () => {
    return (
      <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
        {item.type === 'ecotote' && (
          <>
            <Icon name="icon-water-drop" size={12} className=" tw-text-grey-700" />
            <span>
              {item?.ecoTote?.lastKnownVolume !== null
                ? (Number(item?.ecoTote?.lastKnownVolume) / 1000).toFixed(2)
                : (0).toFixed(2)}
              L
            </span>
          </>
        )}
        {item.type === 'ecoplant' && item?.ecoPlant?.lastFilledDate && (
          <>
            <Icon name="icon-date_range" size={12} className=" tw-text-grey-700" />
            <span>{item?.ecoPlant?.lastFilledDate ? DateFormat(item?.ecoPlant?.lastFilledDate) : ''}</span>
          </>
        )}
        {item.type === 'smartpour' && <NetworkStatus status={item?.smartPour?.network ?? null} />}
      </Text>
    );
  };

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-mb-3">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs" size={12} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button className="hardware-card-item card-item" onClick={() => handleCardClick(item)}>
      <a className="btn-more-action" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
        <Dropdown menu={{ items }}>
          <Space>
            <MoreActionIcon />
          </Space>
        </Dropdown>
      </a>
      <div className="card-info tw-pb-3">
        <div>
          <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-1 tw-mb-1">
            {handleDisplayIconByTypeFromApi(item.type, { color: '#191919', width: 16, height: 16 })}
            <span>{getTrackingTypeFromApi(item)}</span>
          </Text>
          <Text variant="inputLabelLg" className="tw-mt-1 tw-mb-3 tw-text-primary-900">{`${item.serialCode}`}</Text>
          {renderField({ icon: 'icon-person', value: item?.owner?.displayName })}
          {!item?.site ? (
            <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-1 tw-mb-3">
              <Icon name="icon-location_on" size={12} className=" tw-text-grey-700" />
              <span>Unassigned Site</span>
            </Text>
          ) : (
            <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-1 tw-mb-3">
              <Icon name="icon-location_on" size={12} className=" tw-text-grey-700" />
              <span>{item?.site?.displayName}</span>
            </Text>
          )}
          {renderInfoByType()}
        </div>
        <p className="status-section">
          {item?.status && <CommonStatus status={item?.status} />}
          {item?.condition && <CommonStatus status={item?.condition} />}
        </p>
      </div>
      <Divider />
      {!item.error ? (
        <Link to={`#`} className="tw-flex tw-items-center tw-gap-x-1 tw-pt-3">
          <Icon name="icon-flag" size={12} className="tw-text-primary-800" />
          <Text variant="buttonSm" className="tw-text-primary-800">
            Report
          </Text>
        </Link>
      ) : (
        <p></p>
      )}
    </button>
  );
};

export default TrackingCardItem;
