import React from 'react';
import DetailsView from '../DetailsView';
import { HardwareGroupI } from '../../interfaces/hardware';
import Divider from '../Divider';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';
import { DateFormat } from '../../utils/global';
import DisplayQRCode from '../shared/DisplayQRCode';

type Props = {
  group: HardwareGroupI;
};

const HardwareGroupInformation: React.FC<Props> = ({ group }) => {
  return (
    <div className="details-container">
      <DetailsView title="Hardware Group Details">
        <div className="flex-2-column">
          <DetailsView.Field label="Group QR Code" value={<DisplayQRCode data={String(group?.code)} />} />
          <DetailsView.Field label="Hardware Group Code" value={group?.code} />
        </div>
        <Divider />
        <div className="flex-2-column">
          <DetailsView.Field label="Hardware Group Code" value={group?.hardwares?.length ?? 0} />
          <DetailsView.Field
            label="Hardware Type"
            value={
              <p className="type-section tw-flex tw-items-center tw-m-0">
                {handleDisplayIconByTypeFromApi(group?.hardwareType, { color: '#191919', width: 16, height: 16 })}
                <span>{getTypeFromApi(group)}</span>
              </p>
            }
          />
        </div>
        <div className="flex-2-column">
          <DetailsView.Field label="Date Created" value={DateFormat(group?.createdAt)} />
        </div>
        <Divider />
        <div className="flex-2-column">
          <DetailsView.Field label="Remarks" value={group?.remarks} />
        </div>
      </DetailsView>
    </div>
  );
};

export default HardwareGroupInformation;
