import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import TrackingInformation from '../../../components/Tracking/Information';
import { useGetPlatformHardware } from '../../../apis/platforms/hardware';
import PlatformHardwareTopInfo from '../../../components/Platforms/Hardware/TopInfo';
import PlatformHardwareResources from '../../../components/Platforms/Hardware/Resources';

const PlatformHardwareDetailsPage: React.FC = () => {
  const { serialCode } = useParams<{ serialCode: string }>();
  const { data: hardware, isLoading } = useGetPlatformHardware(serialCode);

  return (
    <Spin spinning={isLoading}>
      <PlatformHardwareTopInfo data={hardware} />
      <TrackingInformation data={hardware} />
      <PlatformHardwareResources data={hardware} id={serialCode} />
    </Spin>
  );
};

export default PlatformHardwareDetailsPage;
