import React from 'react';
import { ProductI } from '../../interfaces/product';
import { IconName } from '../Icon/iconName';
import Icon from '../Icon';
import Text from '../Text';
import { getProductCategory } from '../../utils/global';

type Props = {
  item?: ProductI;
};

const ProductCardItem: React.FC<Props> = ({ item }) => {
  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs" size={16} />
        <Text variant="inputLabelSm" className="flex-1 text-capitalize">
          {props?.value}
        </Text>
      </div>
    );
  };
  return (
    <div className="scan-screen-data filling-product-card-item">
      <p className="label tw-block tw-mb-6 tw-text-center">1 Product Scanned:</p>
      <div className="card-item">
        <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
          <Text variant="inputLabelSm">{item?.brandName}</Text>
          <Text variant="bodyLgB" className="tw-text-primary-800">
            {item?.name}
          </Text>
          {renderField({ icon: 'icon-invert_colors', value: `${item?.abv ?? 0}% (ABV)` })}
          {renderField({ icon: 'icon-receipt', value: item?.sku ?? '' })}
          {renderField({ icon: 'icon-wine_bar', value: getProductCategory(item?.category ?? undefined) })}
        </div>
      </div>
    </div>
  );
};

export default ProductCardItem;
