import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '../../apis/products';
import ProductInformation from '../../components/Product/Information';
import ProductTopSection from '../../components/Product/TopSection';
import ProductResources from '../../components/Product/Resources';

const ProductDetailsPage: React.FC = () => {
  const { sku } = useParams<{ sku?: string }>();
  const { data: product, isLoading } = useGetProduct(sku);

  return (
    <Spin spinning={isLoading}>
      <ProductTopSection product={product} />
      <ProductInformation product={product} />
      <ProductResources data={product} id={sku} />
    </Spin>
  );
};

export default ProductDetailsPage;
