import React, { useState } from 'react';
import {
  HardwareItemI,
  HardwareScannedStateI,
  HardwareTypeI,
  HardwareVerifyCollectionI,
  HardwareVerifyTypeI,
} from '../../interfaces/hardware';
import './new.scss';
import HardwareVerifyFilterForm from '../../components/forms/hardware/VerifyFilter';
import { HardwareProgressStepListI } from '../../interfaces';
import FillingEcoPlantScanStep from '../../components/HardwareFilling/ScanEcoPlantStep';
import FillingLotNumberScanStep from '../../components/HardwareFilling/ScanLotNumberStep';
import FillingProductScanStep from '../../components/HardwareFilling/ScanProductStep';
import FillingEcoToteScanStep from '../../components/HardwareFilling/ScanEcoToteStep';
import { FillingParamsI } from '../../interfaces/hardwareFilling';
import { doVerifyHardware } from '../../apis/hardwares';
import FillingVerifyStep from '../../components/HardwareFilling/VerifyStep';
import FillingReviewStep from '../../components/HardwareFilling/ReviewStep';
import SharedHardwareProgressBar from '../../components/shared/Hardware/ProgressBar';
import useNavigateAway from '../../hooks/useNavigateAway';
import HardwareW2WGroupingPage from '../../components/shared/Hardware/W2WGrouping';
import { handleRemoveVerifiedData } from '../../utils/hardware';
import { useNavigate } from 'react-router-dom';
import { SiteI } from '../../interfaces/site';
import { useAppNotification } from '../../hooks/useAppNotification';
import Text from '../../components/Text';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'ecoPLANT',
  },
  {
    step: 2,
    label: 'Product',
  },
  {
    step: 3,
    label: 'Lot Number',
  },
  {
    step: 4,
    label: 'ecoTOTEs',
  },
  {
    step: 5,
    label: 'Verify',
  },
  {
    step: 6,
    label: 'Review',
  },
];

const HardwareFillingNewPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [verifiedData, setVerifiedData] = useState<HardwareVerifyCollectionI>();
  const [filtered, setFiltered] = useState<HardwareVerifyCollectionI>();
  const [scannedData, setScannedData] = useState<FillingParamsI>();
  const [toteScanned, setToteScanned] = useState<{ serialCode: string }[]>([]);
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [chosenType, setChosenType] = useState<HardwareTypeI>();
  const [successData, setSuccessData] = useState();
  const [finalScannedData, setFinalScannedData] = useState<{ serialCode: string }[]>([]);
  const [siteId, setSiteId] = useState<number | undefined>();
  const [site, setSite] = useState<SiteI>();
  const { openNotification } = useAppNotification();

  useNavigateAway({
    when: finalScannedData.length > 0 || !!scannedData,
    handleProceed() {
      setScannedData(undefined);
      setFinalScannedData([]);
      setStopDecodingScanner(true);
    },
  });

  const handleVerify = async () => {
    try {
      const finalData = [...finalScannedData, ...toteScanned];
      const params = {
        hardwareList: finalData,
        verifyType: 'filling',
        siteId: site?.id,
      } as {
        hardwareList: HardwareScannedStateI[];
        verifyType: HardwareVerifyTypeI;
        siteId: number;
      };
      setFinalScannedData(finalData);
      setToteScanned([]);
      const data = await doVerifyHardware(params);
      if (data) {
        openNotification({
          type: data?.numberOfInvalid ? 'warning' : 'success',
          title: (
            <div>
              <span>You have scanned {} Code(s) that includes:</span>
              <ul className="tw-my-1 tw-px-6 tw-pl-[25px]">
                <li>{data.numberOfHardwareScanned} hardware</li>
                {data?.numberOfInvalid ? <li>{data.numberOfInvalid} invalid code(s)</li> : ''}
              </ul>
            </div>
          ),
          content: data?.numberOfInvalid ? (
            <Text variant="bodyMd">Please remove invalid code(s) before proceeding to next step!</Text>
          ) : (
            ''
          ),
        });
      }
      setVerifiedData(data);
      setCurrentStep(5);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyFilter = (values: { serialCode: HardwareScannedStateI['serialCode'] }) => {
    if (values?.serialCode) {
      const hardwareList = verifiedData?.hardwareList?.filter(
        (item: HardwareScannedStateI) => item.serialCode === values.serialCode,
      );
      const newData: any = { ...verifiedData };
      newData.hardwareList = hardwareList;
      setFiltered(newData);
    } else {
      setFiltered(undefined);
    }
  };

  const handleDeleteVerifiedData = (item: HardwareItemI) => {
    const result = handleRemoveVerifiedData(verifiedData, item.serialCode);
    setVerifiedData(result);
    setFinalScannedData((prevItems) => {
      const index = finalScannedData.findIndex((data) => data.serialCode === item.serialCode);
      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }
      return prevItems;
    });
  };

  const handleScanmoreButton = () => {
    setCurrentStep(4);
    setStopDecodingScanner(false);
  };

  const backFunction = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
    if (currentStep === 1) navigate(-1);
  };

  return (
    <div id="pages-hardware-filling-new-container">
      {currentStep !== 7 && (
        <SharedHardwareProgressBar
          currentStep={currentStep}
          title="Filling ecoTOTEs"
          stepList={progressStepList}
          backFunction={backFunction}
        />
      )}
      {currentStep === 5 && (
        <HardwareVerifyFilterForm
          handleVerifyFilter={handleVerifyFilter}
          numberOfHardwareScanned={verifiedData?.numberOfHardwareScanned}
          numberOfInvalid={verifiedData?.numberOfInvalid}
        />
      )}
      {currentStep === 1 && (
        <FillingEcoPlantScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setScannedData}
          scannedData={scannedData}
          setStopDecodingScanner={setStopDecodingScanner}
          stopDecodingScanner={stopDecodingScanner}
          setSite={setSite}
        />
      )}
      {currentStep === 2 && (
        <FillingProductScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setScannedData}
          scannedData={scannedData}
          setStopDecodingScanner={setStopDecodingScanner}
          stopDecodingScanner={stopDecodingScanner}
        />
      )}
      {currentStep === 3 && (
        <FillingLotNumberScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setScannedData}
          scannedData={scannedData}
          setStopDecodingScanner={setStopDecodingScanner}
          stopDecodingScanner={stopDecodingScanner}
        />
      )}
      {currentStep === 4 && (
        <FillingEcoToteScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setScannedData}
          scannedData={scannedData}
          setToteScanned={setToteScanned}
          toteScanned={toteScanned}
          handleVerify={handleVerify}
          setStopDecodingScanner={setStopDecodingScanner}
          stopDecodingScanner={stopDecodingScanner}
        />
      )}
      {currentStep === 5 && (
        <FillingVerifyStep
          setCurrentStep={setCurrentStep}
          verifiedData={filtered ?? verifiedData}
          handleDeleteVerifiedData={handleDeleteVerifiedData}
          handleScanmoreButton={handleScanmoreButton}
          site={site}
        />
      )}
      {currentStep === 6 && (
        <FillingReviewStep
          setScannedData={setScannedData}
          setCurrentStep={setCurrentStep}
          verifiedData={verifiedData}
          scannedData={scannedData}
          setChosenType={setChosenType}
          setSuccessData={setSuccessData}
          successData={successData}
          setFinalScannedData={setFinalScannedData}
          setSiteId={setSiteId}
        />
      )}
      {currentStep === 7 && (
        <HardwareW2WGroupingPage
          site={site}
          siteId={siteId}
          registerType={chosenType}
          successData={successData}
          backTo="/hardware-filling"
        />
      )}
    </div>
  );
};

export default HardwareFillingNewPage;
