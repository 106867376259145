import { Button, Spin } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRole } from '../../apis/roles';
import { CommonStatus } from '../../components/CommonStatus';
import { getModulesList } from '../../apis/modules';
import { useQuery } from 'react-query';
import RoleCollapseDetail from '../../components/Role/RoleCollapseDetail';
import RoleUsers from '../../components/Role/Users';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import DetailsView from '../../components/DetailsView';
import RoleTopInfo from '../../components/Role/TopInfo';

const RoleDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: role, isLoading } = useGetRole(Number(id));
  const { data: moduleData } = useQuery(['modules', 'list', {}], () => getModulesList());
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(true);

  return (
    <Spin spinning={isLoading}>
      <div id="page-role-details-container">
        <RoleTopInfo data={role} />
        <DetailsView title="Role Information">
          <DetailsView.Field label="Name" value={role?.name} />
          <DetailsView.Field label="Status" value={<CommonStatus status={role?.status} />} />
          <DetailsView.Field label="Description" value={role?.description} />
        </DetailsView>
        <div className="content-wrap" style={{ marginTop: 16 }}>
          <div className="details-container">
            <h2
              className={`details-title tw-flex tw-justify-between tw-items-center ${isCollapseOpen ? 'collapse-open' : 'collapse-closed'}`}
            >
              <Text variant="bodyMd">Access</Text>
              <Button
                className="!tw-p-2 !tw-leading-[1px] tw-bg-grey-500 hover:tw-border-grey-500 !tw-border-grey-500 !tw-text-grey-800 !tw-rounded"
                onClick={() => setIsCollapseOpen(!isCollapseOpen)}
              >
                {isCollapseOpen ? <Icon name="icon-remove" /> : <Icon name="icon-add" />}
              </Button>
            </h2>
            <div className={isCollapseOpen ? 'tw-block' : 'tw-hidden'}>
              <Spin spinning={isLoading}>
                <RoleCollapseDetail modules={moduleData?.modules} role={role} />
              </Spin>
            </div>
          </div>
        </div>
        <RoleUsers users={role?.users} />
      </div>
    </Spin>
  );
};

export default RoleDetailsPage;
