import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { HardwareGroupI, HardwareI } from '../../interfaces/hardware';
import { DateFormat } from '../../utils/global';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: HardwareGroupI[];
};

const HardwareGroupsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Group Name',
      width: 150,
      ellipsis: true,
      dataIndex: 'title',
    },
    {
      title: 'Group Code',
      width: 150,
      ellipsis: true,
      dataIndex: 'code',
    },
    {
      title: 'No. of Hardware',
      width: 150,
      ellipsis: true,
      dataIndex: 'hardwares',
      render: (hardwares: HardwareI[]) => {
        return `${hardwares?.length ?? 0}`;
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default HardwareGroupsTable;
