import React, { ReactNode } from 'react';
import { CommonStatus } from '../CommonStatus';
import DangerButton from '../buttons/DangerButton';
import TrashIcon from '../../svgs/TrashIcon';
import { ProductI } from '../../interfaces/product';
import Text from '../Text';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';
import { getProductCategory } from '../../utils/global';
import { Link } from 'react-router-dom';

type Props = {
  item: ProductI;
  canDelete?: boolean;
  handleDeleteItem?: (_: any) => void;
};

const ProductGroupDetailItemCard: React.FC<Props> = ({ item, canDelete = true, handleDeleteItem }) => {
  const onDeleteClick = () => {
    handleDeleteItem?.(item.id);
  };

  const renderDeleteContent = () => {
    return (
      <>
        <Text variant="h3">Remove Product</Text>
        <Text variant="bodyLg" component="p">
          Are you sure you want to remove <span className=" tw-font-bold">{`${item.name} - ${item.sku}`}</span> from
          this product group?
        </Text>
      </>
    );
  };

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <Link to={`/products/${item?.sku}`} className="tw-text-grey-900 hover:tw-opacity-100 hover:tw-text-grey-900">
      <div className="tw-min-h-[180px] tw-min-w-[200px] tw-rounded-lg tw-shadow-simple tw-cursor-pointer">
        <div className="tw-flex tw-flex-col tw-h-full tw-py-4 tw-px-4">
          <Text variant="inputLabelMd" className="tw-mb-1">
            {item?.brandName}
          </Text>
          <div className="tw-flex tw-flex-col tw-gap-3 tw-w-full tw-justify-between">
            <Text variant="bodyLgB" className="tw-text-primary-800">
              <Link to={`/products/${item?.sku}`}>{item?.name}</Link>
            </Text>
            {renderField({ icon: 'icon-invert_colors', value: `${item?.abv ?? 0}% (ABV)` })}
            {renderField({ icon: 'icon-receipt', value: item?.sku ?? '' })}
            {renderField({
              icon: 'icon-wine_bar',
              value: <span className="text-capitalize">{getProductCategory(item?.category ?? undefined)}</span>,
            })}
            <CommonStatus status={item?.status ?? ''} />
          </div>
        </div>
        {canDelete && (
          <div className="report-delete-section">
            <div></div>
            <DangerButton
              icon={<TrashIcon width={12} height={12} />}
              onOk={onDeleteClick}
              content={renderDeleteContent()}
            />
          </div>
        )}
      </div>
    </Link>
  );
};

export default ProductGroupDetailItemCard;
