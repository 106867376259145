import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductGroup } from '../../apis/products';
import ProductGroupTopInfo from '../../components/ProductGroup/TopGroupInfo';
import SizeBox from '../../components/SizeBox';
import ProductGroupInformation from '../../components/ProductGroup/GroupInformation';
import ProductGroupResources from '../../components/ProductGroup/Resources';

const ProductGroupDetailsPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const { data: group, isLoading } = useGetProductGroup(code);

  return (
    <Spin spinning={isLoading}>
      <div>
        <ProductGroupTopInfo data={group} />
        <ProductGroupInformation data={group} />
        <SizeBox height={24} />
        <ProductGroupResources data={group} />
      </div>
    </Spin>
  );
};

export default ProductGroupDetailsPage;
