import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../utils/global';
import { ProductI } from '../../interfaces/product';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const ProductGroupsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Group name',
      dataIndex: 'title',
      width: 150,
      ellipsis: true,
      render: (name: string) => {
        return <span className="text-capitalize">{name}</span>;
      },
    },
    {
      title: 'Group code',
      dataIndex: 'code',
      width: 150,
      ellipsis: true,
      render: (brandName: string) => {
        return <span className="text-capitalize">{brandName}</span>;
      },
    },
    {
      title: 'no. of products',
      dataIndex: 'products',
      width: 150,
      ellipsis: true,
      render: (products: ProductI[]) => {
        return <span className="text-capitalize">{products?.length ?? 0}</span>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default ProductGroupsTable;
