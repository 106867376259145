import { notification } from 'antd';
import axios from 'axios';
import { getRenewToken } from './utils/authentication';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from './constants';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

axiosInstance?.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (responseError) {
    console.log('error', responseError);
    const originalRequest = responseError.config;
    const { data } = responseError?.response || {};
    const error = data?.details || data?.detail || {};
    const status = responseError?.response?.status;
    if (data?.status === 401 && data?.detail && data?.detail?.includes('Permission denied!') && !originalRequest._retry) {
      originalRequest._retry = true;
      const newToken = await getRenewToken();
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return axiosInstance(originalRequest);
    } else if (originalRequest._retry) {
      Cookies.remove(TOKEN_NAME);
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    }
      
    if (status === 520 || status === 413) {
      notification.error({
        message: 'Could not do this action!',
        placement: 'bottomRight',
      });
    }

    if (data?.detail && !originalRequest._retry) {
      const message = data?.detail;
      if (message !== 'code exists' && message !== 'Forbidden!') {
        notification.error({
          message: message,
          placement: 'bottomRight',
        });
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
