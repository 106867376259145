import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetHardwareGroup } from '../../apis/hardwares';
import TrackingGroupTopInfo from '../../components/Tracking/TopGroupInfo';
import HardwareGroupInformation from '../../components/Tracking/GroupInformation';
import SizeBox from '../../components/SizeBox';
import GroupHardwareAndAttachment from '../../components/shared/Hardware/GroupHardwareAndAttachment';

const TrackingGroupDetailsPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const { data: group, isLoading } = useGetHardwareGroup(code);

  return (
    <Spin spinning={isLoading}>
      <div id="tracking-details-container">
        <TrackingGroupTopInfo data={group} />
        <HardwareGroupInformation group={group} />
        <SizeBox height={24} />
        <GroupHardwareAndAttachment data={group} />
      </div>
    </Spin>
  );
};

export default TrackingGroupDetailsPage;
