import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetPartner } from '../../../apis/partners';
import PartnerName from '../../../components/Partner/Name';
import PartnerInformation from '../../../components/Partner/Information';
import PartnerHistoryAndAttachment from '../../../components/Partner/HistoryAndAttachment';

const PlatformPartnerDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: partner, isLoading } = useGetPartner(Number(id));

  return (
    <Spin spinning={isLoading}>
      <div className="tw-flex tw-flex-col tw-gap-4">
        {partner && (
          <>
            <PartnerName partner={partner} />
            <PartnerInformation partner={partner} />
            <PartnerHistoryAndAttachment data={partner} />
          </>
        )}
      </div>
    </Spin>
  );
};

export default PlatformPartnerDetailsPage;
