import { Tag } from 'antd';
import React from 'react';
import { SiteI } from '../../interfaces/site';
import { getCountryFromCode } from '../../utils/global';
import DetailsView from '../DetailsView';

type Props = {
  site: SiteI;
};

const SiteInformation: React.FC<Props> = ({ site }) => {
  return (
    <div className="tw-flex tw-gap-6 tw-justify-between sm:tw-flex-col">
      <DetailsView title="Site Information">
        <DetailsView.Field label="Site Name" value={site?.legalName} />
        <DetailsView.Field label="Display Name" value={site?.displayName} />
        <DetailsView.Field label="Type of Site" value={site?.type} />
        <DetailsView.Field label="Market" value={<Tag>{site?.market}</Tag>} />
        <DetailsView.Field label="Description" value={<b className="tw-whitespace-pre-wrap">{site?.description}</b>} />
      </DetailsView>
      <DetailsView title="Location">
        <DetailsView.Field label="Address Line 1" value={site?.addressLine1} />
        <DetailsView.Field label="Address Line 2" value={site?.addressLine2} />
        <DetailsView.Field label="Address Line 3" value={site?.addressLine3} />
        <DetailsView.Field label="Country" value={getCountryFromCode(site?.country)} />
        <DetailsView.Field label="Region" value={site?.region} />
        <DetailsView.Field label="State / Province" value={site?.state} />
        <DetailsView.Field label="Postal / Zip Code" value={site?.postalCode} />
      </DetailsView>
    </div>
  );
};

export default SiteInformation;
