import { Checkbox } from 'antd';
import React, { Fragment, useMemo } from 'react';
import ActionSection from './ActionSection';
import { ModuleI } from '../../interfaces/module';
import { RoleI } from '../../interfaces/role';
import { CheckboxOptionI } from '../../interfaces';
import { getModulePermissions } from '../../utils/global';

type Props = {
  modules?: ModuleI[];
  role?: RoleI;
};

const RoleHardwareCollapseDetail: React.FC<Props> = ({ modules, role }) => {
  const hardwareCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Hardware')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const registrationCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Registration')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const allocationCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Allocation')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const depletedCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Depletion')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const fillingCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Filling')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const groupCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Hardware_grouping')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const transferCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Transfer')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  return (
    <Fragment>
      <ActionSection
        label="Tracking"
        content={<Checkbox.Group options={hardwareCheckboxOptions} value={role?.colHardware} />}
      />
      <ActionSection
        label="Hardware Grouping"
        content={<Checkbox.Group options={groupCheckboxOptions} value={role?.colHardware_grouping} />}
      />
      <ActionSection
        label="Registration"
        content={<Checkbox.Group options={registrationCheckboxOptions} value={role?.colRegistration} />}
      />
      <ActionSection
        label="Allocation"
        content={<Checkbox.Group options={allocationCheckboxOptions} value={role?.colAllocation} />}
      />
      <ActionSection
        label="Transfer"
        content={<Checkbox.Group options={transferCheckboxOptions} value={role?.colTransfer} />}
      />
      <ActionSection
        label="Fill"
        content={<Checkbox.Group options={fillingCheckboxOptions} value={role?.colFilling} />}
      />
      <ActionSection
        label="Deplete"
        content={<Checkbox.Group options={depletedCheckboxOptions} value={role?.colDepletion} />}
      />
    </Fragment>
  );
};

export default RoleHardwareCollapseDetail;
