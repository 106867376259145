import React, { useEffect, useState } from 'react';
import { getCountryFromCode } from '../../utils/global';
import { ProductI } from '../../interfaces/product';
import { getProductCategory } from '../../apis/productCategories';
import { ProductCategoryI } from '../../interfaces/productCategory';
import Divider from '../Divider';
import SizeBox from '../SizeBox';
import DetailsView from '../DetailsView';
import DisplayQRCode from '../shared/DisplayQRCode';
import DisplayBarCode from '../shared/DisplayBarCode';
import Text from '../Text';

type Props = {
  product: ProductI;
};

const ProductInformation: React.FC<Props> = ({ product }) => {
  const [parentCategory, setParentCategory] = useState<string>();
  const [secondCategory, setSecondCategory] = useState<string>();
  const [thirdCategory, setThirdCategory] = useState<string>();
  const [tagging, setTagging] = useState<{ key: string; value: any }[]>([]);

  const handleGetParrentCategory = async (category: ProductCategoryI) => {
    const result: any = await getProductCategory(Number(category?.parentId));
    if (!result) return null;

    if (result?.parentId === null) {
      setParentCategory(result?.name);
      setSecondCategory(category?.name);
    } else if (result?.parent && result?.parent?.parentId === null) {
      const secondaryName = result?.name;
      setParentCategory(result?.parent?.name);
      setSecondCategory(secondaryName);
      setThirdCategory(category?.name);
    } else {
      handleGetParrentCategory(result);
    }
  };

  useEffect(() => {
    if (product) {
      if (product.category) {
        if (product?.category?.parentId === null) {
          setParentCategory(product?.category?.name);
        } else {
          handleGetParrentCategory(product?.category);
        }
      }
      const taggingArr = Object.entries(product?.tagging).map(([key, value]) => ({ key, value }));
      setTagging(taggingArr);
    }
  }, [product]);

  return (
    <>
      <DetailsView title="Product Information">
        <div className="flex-3-column">
          <DetailsView.Field label="Brand Owner" value={product?.partner?.legalName} />
          <DetailsView.Field label="Brand" value={product?.brandName} />
          <DetailsView.Field label="Product Name" value={product?.name} />
        </div>
        <div className="flex-3-column">
          <DetailsView.Field label="Product Origin" value={getCountryFromCode(product?.productionOrigin)} />
          <DetailsView.Field label="ABV" value={`${product?.abv ?? 0}%`} />
        </div>
        <Divider />
        <div className="flex-3-column">
          <DetailsView.Field label="Product SKU" value={product?.sku} />
          <DetailsView.Field label="Product QR Code" value={<DisplayQRCode data={product?.sku} />} />
          <DetailsView.Field label="Product Barcode" value={<DisplayBarCode data={product?.sku} />} />
        </div>
        <Divider />
        <div className="flex-3-column">
          <DetailsView.Field label="Beverage Type" value={parentCategory} className="text-capitalize" />
          {secondCategory && <DetailsView.Field label="Beverage Classification" value={secondCategory} />}
          {thirdCategory && <DetailsView.Field label="Beverage Sub-Classification" value={thirdCategory} />}
        </div>
        <SizeBox height={12} />
        <Divider />
        <div className="details-content-wrap">
          {tagging?.length > 0 && (
            <>
              <div className="tagging-section">
                <Text variant="inputLabelSm" className="tw-mb-3 tw-block">
                  Key Value Pair Tagging (Optional)
                </Text>
                <div className="value-section">
                  <Text variant="bodyMd" component="p" className="flex-2-column tw-gap-2 label tw-text-grey-700">
                    <span className="tw-min-w-[180px] tw-inline-block">Key Name</span>{' '}
                    <span className="tw-min-w-[180px] tw-inline-block tw-ml-2">Value</span>{' '}
                  </Text>
                  <div>
                    {tagging.map((item) => (
                      <p key={item.key} className="flex-2-column tw-mt-0 tw-mb-[12px]">
                        <Text variant="bodyMdB" component="span" className="tw-min-w-[180px]">
                          {item.key && item.key !== '' ? item.key : 'Not Applicable'}
                        </Text>
                        <Text variant="bodyMdB" component="span" className="tw-min-w-[180px]">
                          {typeof item.value === 'object'
                            ? JSON.stringify(item.value)
                            : item.value && item.value !== ''
                              ? item.value
                              : 'Not Applicable'}
                        </Text>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <Divider />
              <SizeBox height={12} />
            </>
          )}
          <DetailsView.Field label="Remarks" value={product?.notes} />
        </div>
        <DetailsView title="Related Bottle Codes" className="tw-rounded-none tw-shadow-none !tw-p-0">
          <div className="flex-3-column">
            {product?.bottleCodes?.map((code: string) => (
              <div className="item" key={code}>
                <div className="label">Bottle Code</div>
                <div className="value">{code}</div>
              </div>
            ))}
          </div>
        </DetailsView>
      </DetailsView>
    </>
  );
};

export default ProductInformation;
