import React from 'react';
import { ProductGroupI } from '../../interfaces/product';
import DetailsView from '../DetailsView';
import DisplayQRCode from '../shared/DisplayQRCode';
import { DateFormat } from '../../utils/global';
import Divider from '../Divider';

type Props = {
  data: ProductGroupI;
};

const ProductGroupInformation: React.FC<Props> = ({ data }) => {
  return (
    <div className="details-container">
      <DetailsView title="Product Group Details">
        <div className="flex-3-column">
          <DetailsView.Field label="Product Group Code" value={<DisplayQRCode data={data?.code} />} />
          <DetailsView.Field label="Product Group Code" value={data?.code} />
        </div>
        <Divider />
        <DetailsView.Field label="Date Created" value={DateFormat(data?.createdAt)} />
        <Divider />
        <DetailsView.Field label="Number of Products Within Group" value={data?.products.length ?? 0} />
        <Divider />
        <DetailsView.Field label="Remarks" value={data?.remarks} />
      </DetailsView>
    </div>
  );
};

export default ProductGroupInformation;
