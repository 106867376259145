import React from 'react';
import Barcode from 'react-barcode';
import './DisplayBarCode.scss';

type Props = {
  data: string;
};

export const DisplayBarCode: React.FC<Props> = ({ data }) => {
  return (
    <div className="barcode-container">
      <Barcode value={data} width={1} height={45} font="Oxygen" fontSize={12} />
    </div>
  );
};

export default DisplayBarCode;
