import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetUser } from '../../apis/users';
import UserName from '../../components/User/Name';
import UserInformation from '../../components/User/Information';
import UserResources from '../../components/User/Resources';

const UserDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: user, isLoading } = useGetUser(Number(id));
  return (
    <Spin spinning={isLoading}>
      <UserName user={user} />
      <UserInformation user={user} />
      <UserResources data={user} id={id} />
    </Spin>
  );
};

export default UserDetailsPage;
