import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { HardwareItemI } from '../../interfaces/hardware';
import { DateFormat } from '../../utils/global';
import { TransferI } from '../../interfaces/transfer';
import { handleGetUniqType } from '../../utils/hardware';
import Text from '../Text';
import Icon from '../Icon';
import { PartnerI } from '../../interfaces/partner';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: TransferI[];
};

const TransfersTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Report',
      width: 150,
      dataIndex: 'code',
      ellipsis: true,
    },
    {
      title: 'transferred to',
      width: 150,
      dataIndex: 'newOwner',
      ellipsis: true,
      render: (newOwner: PartnerI) => {
        return <span>{newOwner?.displayName}</span>;
      },
    },
    {
      title: 'no of hardware',
      dataIndex: 'hardwareList',
      width: 120,
      ellipsis: true,
      render: (hardwareList: HardwareItemI[]) => {
        return <span>{hardwareList?.length}</span>;
      },
    },
    {
      title: 'hardware type',
      dataIndex: 'hardwareList',
      width: 120,
      ellipsis: true,
      render: (hardwareList: HardwareItemI[]) => {
        return (
          <span className="tw-flex tw-gap-2 tw-flex-wrap">
            {handleGetUniqType(hardwareList ?? []).map((item: { type: string; count: number }, index: number) => (
              <div key={index}>
                {item.type.includes('ecoTOTE') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="labelMd">{item.count}</Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
                {item.type.includes('ecoPLANT') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="labelMd">{item.count}</Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
                {item.type.includes('SmartPour') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="labelMd">{item.count}</Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
              </div>
            ))}
          </span>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default TransfersTable;
