import { Checkbox, Form } from 'antd';
import React, { useEffect } from 'react';
import { HardwareHistorySearchParams } from '../../interfaces/hardwareHistory';
import Text from '../Text';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { displayUserHistoryType } from '../../utils/user';

type FormParams = {
  category: string;
  user: string;
  search: string;
};

type Props = {
  queryParams: HardwareHistorySearchParams;
  onChange(newParams: Partial<HardwareHistorySearchParams>): void;
};

const UserHistoryFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();

  const categoryOptions = Object.keys(displayUserHistoryType).map((item) => {
    return { label: displayUserHistoryType[item], value: item };
  });

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: { category: values?.category, user: values?.user },
      search: values?.search,
    });
  };

  const userOptions = ['user', 'system'];

  useEffect(() => {
    if (queryParams.filter) {
      const category = queryParams.filter?.category ? queryParams.filter?.category.split(',') : [];
      const user = queryParams.filter?.user ? queryParams.filter?.user.split(',') : [];
      form.setFieldsValue({
        category,
        user,
      });
    }
  }, [queryParams]);

  return (
    <div className="component-hardware-filter-content-container partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Text variant="h4" className="tw-mb-3">
              Category
            </Text>
            <Form.Item name="category">
              <Checkbox.Group style={{ gap: 8 }}>
                {categoryOptions.map((item: { label: string; value: string }) => (
                  <Checkbox key={item.value} value={item.value}>
                    {item.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Text variant="h4" className="tw-mb-3">
              Created by
            </Text>
            <Form.Item name="user">
              <Checkbox.Group style={{ gap: 8 }}>
                {userOptions.map((item: string) => (
                  <Checkbox key={item} value={item}>
                    <span className="text-capitalize">{item}</span>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UserHistoryFilterContent;
