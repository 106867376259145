import { Dropdown, Form, Input } from 'antd';
import React from 'react';
import IconButton from '../../buttons/IconButton';
import './VerifyFilter.scss';
import SearchInputIcon from '../../../svgs/SearchInputIcon';
import FilterIcon from '../../../svgs/FilterIcon';
import HardwareIcon from '../../../svgs/HardwareIcon';
import QuestionMarkIcon from '../../../svgs/QuestionMarkIcon';
import { ProductGroupScannedStateI } from '../../../interfaces/product';

type Props = {
  numberOfProductsScanned?: number;
  numberOfInvalid?: number;
  type?: string;
  handleVerifyFilter: (values: { sku: ProductGroupScannedStateI['sku'] }) => void;
};

const ProductGroupVerifyFilterForm: React.FC<Props> = ({
  numberOfProductsScanned,
  numberOfInvalid,
  type,
  handleVerifyFilter,
}) => {
  const [form] = Form.useForm();

  return (
    <div className="component-hardwares-verify-filter-form filter-form-container">
      <div className="form-wrap">
        <Form form={form} requiredMark={false} colon={false} onFinish={handleVerifyFilter} scrollToFirstError>
          <div className="form-container">
            <div className="form-content">
              <p className="label-wrap">
                <span className="hardware-scanned-text">
                  <HardwareIcon /> <span>{`${numberOfProductsScanned ?? 0} ${type ?? 'Products'} Scanned`}</span>
                </span>
                <span className="invalid-hardware-text">
                  <QuestionMarkIcon /> <span>{`${numberOfInvalid ?? 0} Invalid Products`}</span>
                </span>
              </p>
              <div className="seach-wrap">
                <Form.Item name="sku" className="search-box">
                  <Input placeholder="Search" prefix={<SearchInputIcon />} />
                </Form.Item>
                <Dropdown
                  dropdownRender={() => <div>Hallo</div>}
                  trigger={['click']}
                  overlayClassName="filter-drowdown"
                >
                  <IconButton icon={<FilterIcon height={20} />} />
                </Dropdown>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ProductGroupVerifyFilterForm;
