import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { HardwareItemI } from '../../interfaces/hardware';
import { DateFormat } from '../../utils/global';
import { AllocationTypeI, HardwareAllocationI } from '../../interfaces/hardwareAllocation';
import { AllocationTypeComponent } from '../AllocationType';
import { Tag } from 'antd';
import { SiteI } from '../../interfaces/site';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: HardwareAllocationI[];
};

const HardwareAllocationsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Report',
      width: 200,
      dataIndex: 'uuid',
      ellipsis: true,
      render: (uuid: string) => {
        return <span>{`${uuid}`}</span>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
      ellipsis: true,
      render: (type: AllocationTypeI) => {
        return <AllocationTypeComponent type={type} />;
      },
    },
    {
      title: 'Hardware',
      dataIndex: 'hardwareList',
      width: 120,
      ellipsis: true,
      render: (hardwareList: HardwareItemI[]) => {
        return <span>{hardwareList?.length}</span>;
      },
    },
    {
      title: 'Site',
      width: 150,
      dataIndex: 'site',
      ellipsis: true,
      render: (site: SiteI) => {
        return <Tag className="text-capitalize tw-truncate tw-max-w-[150px]">{site?.displayName}</Tag>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default HardwareAllocationsTable;
