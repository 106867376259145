import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetHardware } from '../../apis/hardwares';
import TrackingInformation from '../../components/Tracking/Information';
import TrackingTopInfo from '../../components/Tracking/TopInfo';
import TrackingResources from '../../components/Tracking/Resources';

const TrackingDetailsPage: React.FC = () => {
  const { serialCode } = useParams<{ serialCode: string }>();
  const { data: hardware, isLoading } = useGetHardware(serialCode);

  return (
    <Spin spinning={isLoading}>
      <TrackingTopInfo data={hardware} />
      <TrackingInformation data={hardware} />
      <TrackingResources data={hardware} id={serialCode} />
    </Spin>
  );
};

export default TrackingDetailsPage;
