import React, { Fragment } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import { Modal, Tabs, TabsProps } from 'antd';
import { HardwareGroupI, HardwareScannedStateI, HardwareTypeI } from '../../interfaces/hardware';
import './RegisterScanStep.scss';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import ConfirmButton from '../buttons/ConfirmButton';
import { convertToHardwareTypeAPI } from '../../utils/hardware';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import SharedScanQRCode from '../shared/Hardware/ScanQRCodeScreen';
import SharedManualScreen from '../shared/Hardware/ManualScreen';
import ScanTabsContent from '../shared/Hardware/ScanTabsContent';

const DiscardConfirmContent = () => {
  return (
    <div>
      <h2>Are you sure you want to close this Scanning session?</h2>
      <p>All your currently scanned Hardware will be lost.</p>
    </div>
  );
};

type Props = {
  registerData: HardwareScannedStateI[];
  finalScannedData?: HardwareScannedStateI[];
  registerType?: HardwareTypeI;
  stopDecodingScanner: boolean;
  action?: string;
  group?: HardwareGroupI;
  setCurrentStep: (step: number) => void;
  setRegisterData: (_: any) => void;
  setFinalScannedData?: (_: any) => void;
  handleVerify: () => void;
  setStopDecodingScanner: (_: any) => void;
  setVerifiedData: (_: any) => void;
  setRegisterType?: (_: any) => void;
};

const HardwareRegisterScanStep: React.FC<Props> = ({
  registerData,
  finalScannedData = [],
  registerType,
  stopDecodingScanner,
  action = 'create',
  group,
  setCurrentStep,
  setRegisterData,
  setFinalScannedData,
  handleVerify,
  setStopDecodingScanner,
  setVerifiedData,
  setRegisterType,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const handleDiscard = () => {
    if (action === 'update') {
      setCurrentStep(3);
      setRegisterData([]);
      const hardware = group?.hardwares?.map((item) => {
        return {
          serialCode: item.serialCode,
          type: item.type,
        };
      });
      setFinalScannedData?.(hardware);
    } else {
      setRegisterData([]);
      setFinalScannedData?.([]);
      setCurrentStep(3);
      setRegisterType?.(undefined);
    }
    setVerifiedData({});
  };

  const validateScanResult = (value: string) => {
    let status = 'success';
    const validateData = [...finalScannedData, ...registerData];
    if (validateData.length > 0) {
      if (validateData.find((data: HardwareScannedStateI) => data.serialCode === value)) {
        status = 'error';
      }
    }

    setTimeout(() => {
      if (status === 'success') {
        const successModal = modal.success({
          type: 'success',
          title: 'Successfully Scanned!',
          content: 'Code detected and added to scanned list!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <CheckCircleIcon width={16} height={16} color="#5FB670" />,
          width: 345,
        });
        const newType = convertToHardwareTypeAPI(registerType);

        const newData = {
          ...newType,
          serialCode: value,
        } as HardwareScannedStateI;
        setRegisterData([...registerData, newData]);

        setTimeout(() => {
          successModal.destroy();
        }, 1 * 1000);
      }

      if (status === 'error') {
        const errorModal = modal.error({
          type: 'error',
          title: 'Failed Scanning!',
          content: 'We detected a duplicated scan. Please check again!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <ErrorModalIcon color="#B13939" />,
          width: 345,
        });

        setTimeout(() => {
          errorModal.destroy();
        }, 1 * 1000);
      }
    }, 300);
  };

  const handleScanOrManualSubmit = (result: any) => {
    validateScanResult(result.text);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <SharedScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          scanTabsContent={<ScanTabsContent registerData={registerData} registerType={registerType} />}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <SharedManualScreen
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanTabsContent={<ScanTabsContent registerData={registerData} registerType={registerType} />}
        />
      ),
    },
  ];

  const handleFinishScanning = () => {
    handleVerify();
    setStopDecodingScanner(true);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  return (
    <div className="component-hardware-register-scan-step">
      <div className="wrapper">
        <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          <ConfirmButton label="Discard" onOk={handleDiscard} content={<DiscardConfirmContent />} type="error" />
          <PrimaryButton label="Finish Scanning" onClick={handleFinishScanning} />
        </div>
      </div>
    </div>
  );
};

export default HardwareRegisterScanStep;
