import React from 'react';
import { Avatar } from '../User/Avatar';
import { DateTimeFormat } from '../../utils/global';
import Divider from '../Divider';
import SizeBox from '../SizeBox';
import Text from '../Text';
import { CommonStatus } from '../CommonStatus';
import { UserHistoryI } from '../../interfaces/user';
import { displayUserHistoryType } from '../../utils/user';
import Icon from '../Icon';
import { Link } from 'react-router-dom';

type Props = {
  item: UserHistoryI;
};

const UserHistoryItem: React.FC<Props> = ({ item }) => {
  return (
    <div className="tw-p-4">
      <div className="tw-flex tw-justify-between tw-flex-wrap tw-mb-3 tw-items-center">
        <Text variant="labelMd" className="tw-text-grey-700">
          {displayUserHistoryType[item?.eventName]}
        </Text>
        <Text variant="bodyMd" className="tw-text-grey-800">
          {DateTimeFormat(item?.createdAt)}
        </Text>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-mb-3 tw-flex-wrap">
        {!item?.relations?.impersonatee && item?.relations?.user && (
          <Avatar
            id={item?.relations?.user.id}
            className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
          />
        )}
        {!item?.relations?.impersonatee && item?.relations?.user && (
          <Text variant="bodyMdB">{item?.relations?.user?.username}</Text>
        )}
        {item?.eventName === 'updateStatus' && (
          <Text variant="bodyLg" className="tw-flex tw-gap-1 tw-items-center">
            Status changed from <CommonStatus status={item?.oldRecord?.status} /> to
            <CommonStatus status={item?.newRecord?.status} />
            {item?.relations !== null && (
              <>
                by
                <span className="tw-flex tw-items-center">
                  <Avatar
                    id={item?.relations?.updatedBy.id}
                    className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
                  />{' '}
                  &nbsp;
                  <Text variant="bodyMdB">{item?.relations?.updatedBy?.username}</Text>
                </span>
              </>
            )}
          </Text>
        )}
        {item?.eventName === 'created' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            was created by &nbsp;
            <span className="tw-flex tw-items-center">
              <Avatar
                id={item?.relations?.createdBy.id}
                className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
              />{' '}
              &nbsp;
              <Text variant="bodyMdB">{item?.relations?.createdBy?.username}</Text>
            </span>
          </Text>
        )}
        {item?.eventName === 'update' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            was updated by &nbsp;
            <span className="tw-flex tw-items-center">
              <Avatar
                id={item?.relations?.createdBy.id}
                className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
              />{' '}
              &nbsp;
              <Text variant="bodyMdB">{item?.relations?.createdBy?.username}</Text>
            </span>
          </Text>
        )}
        {item?.eventName === 'loggedIn' && <Text variant="bodyLg">User logged in </Text>}
        {item?.eventName === 'loggedOut' && <Text variant="bodyLg">User logged out </Text>}
        {item?.eventName === 'verified' && (
          <Text variant="bodyLg" className="tw-flex tw-items-center">
            <Icon name="icon-check" size={24} className="tw-text-success-500 tw-mr-3" /> user was verified
          </Text>
        )}
        {item?.eventName === 'addSite' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            has been assigned to &nbsp;
            <span className="tw-text-primary-800">
              {' '}
              <Link to={`/sites/${item?.relations?.site?.id}`}>{item?.relations?.site?.legalName}</Link>
            </span>
            &nbsp; by &nbsp;
            <span className="tw-flex tw-items-center">
              <Avatar
                id={item?.relations?.assignBy.id}
                className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
              />{' '}
              &nbsp;
              <Text variant="bodyMdB">{item?.relations?.assignBy?.username}</Text>
            </span>
          </Text>
        )}
        {item?.eventName === 'removeSite' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            has been unassigned from &nbsp;
            <span className="tw-text-primary-800">
              {' '}
              <Link to={`/sites/${item?.relations?.site?.id}`}>{item?.relations?.site?.legalName}</Link>
            </span>
            &nbsp; by &nbsp;
            <span className="tw-flex tw-items-center">
              <Avatar
                id={item?.relations?.assignBy.id}
                className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
              />{' '}
              &nbsp;
              <Text variant="bodyMdB">{item?.relations?.assignBy?.username}</Text>
            </span>
          </Text>
        )}
        {item?.eventName === 'addRole' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            has been assigned to &nbsp;
            <span className="tw-font-bold"> {item?.relations?.role?.name}</span>
            &nbsp; by &nbsp;
            <span className="tw-flex tw-items-center">
              <Avatar
                id={item?.relations?.assignBy.id}
                className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
              />{' '}
              &nbsp;
              <Text variant="bodyMdB">{item?.relations?.assignBy?.username}</Text>
            </span>
          </Text>
        )}
        {item?.eventName === 'removeRole' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            has been unassigned from &nbsp;
            <span className="tw-font-bold"> {item?.relations?.role?.name}</span>
            &nbsp; by &nbsp;
            <span className="tw-flex tw-items-center">
              <Avatar
                id={item?.relations?.assignBy.id}
                className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
              />{' '}
              &nbsp;
              <Text variant="bodyMdB">{item?.relations?.assignBy?.username}</Text>
            </span>
          </Text>
        )}
        {item?.eventName === 'impersonateLoggedOut' && (
          <div className="tw-flex tw-items-center tw-gap-1">
            <Avatar
              id={item?.relations?.impersonatee.id}
              className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
            />{' '}
            &nbsp;
            <Text variant="bodyMdB">{item?.relations?.impersonatee?.username}</Text>
            <Text variant="bodyLg" className="tw-flex tw-items-center tw-gap-1">
              ended impersonation of &nbsp;
              <span className="tw-flex tw-items-center">
                <Avatar
                  id={item?.relations?.impersonator.id}
                  className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
                />{' '}
                &nbsp;
                <Text variant="bodyMdB">{item?.relations?.impersonator?.username}</Text>
              </span>
            </Text>
          </div>
        )}
        {item?.eventName === 'impersonateLoggedIn' && (
          <div className="tw-flex tw-items-center tw-gap-1">
            <Avatar
              id={item?.relations?.impersonatee.id}
              className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
            />{' '}
            &nbsp;
            <Text variant="bodyMdB">{item?.relations?.impersonatee?.username}</Text>
            <Text variant="bodyLg" className="tw-flex tw-items-center tw-gap-1">
              started impersonation of &nbsp;
              <span className="tw-flex tw-items-center">
                <Avatar
                  type="partner"
                  id={item?.relations?.impersonator.id}
                  className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
                />{' '}
                &nbsp;
                <Text variant="bodyMdB">{item?.relations?.impersonator?.username}</Text>
              </span>
            </Text>
          </div>
        )}
      </div>
      <SizeBox height={16} />
      <Divider />
    </div>
  );
};

export default UserHistoryItem;
