import { Drawer, notification } from 'antd';
import React, { useState } from 'react';
import Text from '../Text';
import Icon from '../Icon';
import SecondaryButton from '../buttons/SecondaryButton';
import Countdown from '../CountDown';
import { useMutation, useQueryClient } from 'react-query';
import { doEmailRetrigger } from '../../apis/partners';

type Props = {
  email: string;
};

const EmailRetriggerButton: React.FC<Props> = ({ email }) => {
  const queryClient = useQueryClient();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isStartCountdown, setIsStartCountdown] = useState<boolean>(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const { mutate: emailRetriggerMutate } = useMutation(doEmailRetrigger, {
    onSuccess: async () => {
      notification.info({
        message: 'Resend verification email!',
        placement: 'top',
      });
      queryClient.invalidateQueries();
    },
    onError: () => {
      notification.info({
        message: 'Your account has already been activated! Please check with your Partner admin.',
        placement: 'top',
      });
    },
  });

  const onSubmit = () => {
    setIsStartCountdown(true);
    emailRetriggerMutate(email);
  };

  return (
    <>
      <button
        className="tw-text-primary-500 tw-flex tw-items-center tw-border-primary-500 tw-bg-white tw-border-solid tw-border tw-rounded tw-py-1 tw-px-3"
        onClick={showDrawer}
      >
        <Text variant="buttonSm" component="span" className="tw-text-primary-500 tw-mr-2">
          Resend Verification Email
        </Text>
        <Icon name="icon-chevron_right" size={16} />
      </button>
      <Drawer
        title={<Text variant="h3">Resend Verification Email</Text>}
        onClose={onCloseDrawer}
        open={openDrawer}
        rootClassName="common-drawer-container"
        width={462}
      >
        <div className="">
          <Text variant="bodyLg">
            A verification email will be sent to Administrative Email:{' '}
            <span className="tw-text-primary-500">{email}</span>
          </Text>
          <Text variant="bodyLg">
            Please remind the Partner admin to verify and set up password to activate their Partner Profile.
          </Text>
        </div>
        <SecondaryButton
          label="Resend Verification Email"
          onClick={onSubmit}
          className="tw-w-full tw-mt-8"
          disabled={isStartCountdown}
        />
        {isStartCountdown && (
          <p>
            You can try resend the verification email in{' '}
            {
              <Countdown
                isStartCountdown={isStartCountdown}
                totalOfTime={29}
                setIsStartCountdown={setIsStartCountdown}
              />
            }
          </p>
        )}
      </Drawer>
    </>
  );
};

export default EmailRetriggerButton;
