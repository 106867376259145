import { Button, message } from 'antd';
import React, { Fragment, useState } from 'react';
import { HardwareTypeI } from '../../interfaces/hardware';
import PrimaryButton from '../buttons/PrimaryButton';
import './RegisterChooseTypeStep.scss';
import { Notice } from '../Notice';
import SmartPOURIcon from '../../svgs/hardwareTypes/SmartPOURIcon';
import EcoPlantIcon from '../../svgs/hardwareTypes/EcoPlantIcon';
import EcoTOTEIcon from '../../svgs/hardwareTypes/EcoTOTEIcon';
import { Link, useOutletContext } from 'react-router-dom';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import WarningIcon from '../../svgs/WarningIcon';
import Ecotote45Image from '../../images/ecotote4.5.png';
import Ecotote175Image from '../../images/ecotote1.75.png';
import SmartPourImage from '../../images/smartpour.png';
import EcoplantImage from '../../images/ecoplant.png';
import PlatformIssueReportingModal from '../PlatformIssueReportingModal';
import { CurrentUserI } from '../../interfaces/auth';
import Text from '../Text';

type Props = {
  registerType?: HardwareTypeI;
  setRegisterType: (_: any) => void;
  setCurrentStep: (step: number) => void;
  actionType?: 'hardware' | 'group';
};

const HardwareRegisterChooseTypeStep: React.FC<Props> = ({
  registerType,
  setCurrentStep,
  setRegisterType,
  actionType = 'hardware',
}) => {
  const currentUser: CurrentUserI = useOutletContext();
  const [reportingModalOpen, setReportingModalOpen] = useState<boolean>(false);

  const handleContinueClick = () => {
    if (!registerType) {
      message.error('You have to choose a type for continue!');
    } else {
      setCurrentStep(2);
    }
  };

  return (
    <div className="component-hardware-register-choose-type-step">
      <div className="wrapper">
        {actionType === 'group' && (
          <Notice
            icon={<WarningIcon />}
            type="warning"
            description={
              <Fragment>
                <p>
                  Please make sure the hardware you’re about to group are registered. If it’s not, please go through the
                  registration first.
                </p>
                <Link to={`/hardware-registrations/new`}>
                  <span>Go to Registration</span>
                  <RightArrowIcon width={16} height={16} />{' '}
                </Link>
              </Fragment>
            }
            closable={false}
          />
        )}
        {actionType === 'hardware' && (
          <Notice
            type="info"
            description={
              <Text variant="bodyMd">
                Check for any faults before registering your hardware. In the event of any hardware faults, please{' '}
                <span>
                  <Button
                    className="tw-p-0 tw-text-primary-500 hover:!tw-text-primary-400 tw-leading-none tw-underline"
                    type="link"
                    onClick={() => setReportingModalOpen(true)}
                  >
                    contact us
                  </Button>
                </span>{' '}
                for support.
              </Text>
            }
            closable={false}
          />
        )}
        <p className="choose-type-text">Choose a hardware type</p>
        <div className="type-buttons-section">
          <Button
            onClick={() => setRegisterType('ecotote4.5l')}
            className={registerType === 'ecotote4.5l' ? 'selected' : ''}
          >
            <div className="image-section">
              <img src={Ecotote45Image} alt="Ecotote 4.5L" />
            </div>
            <EcoTOTEIcon /> <span>ecoTOTE 4.5L</span>
          </Button>
          <Button
            onClick={() => setRegisterType('ecotote1.75l')}
            className={registerType === 'ecotote1.75l' ? 'selected' : ''}
          >
            <div className="image-section">
              <img src={Ecotote175Image} alt="Ecotote 1.75L" />
            </div>
            <EcoTOTEIcon /> <span>ecoTOTE 1.75L</span>
          </Button>
          <Button
            onClick={() => setRegisterType('smartpour')}
            className={registerType === 'smartpour' ? 'selected' : ''}
          >
            <div className="image-section">
              <img src={SmartPourImage} alt="SmartPour" />
            </div>
            <SmartPOURIcon /> <span>SmartPour</span>
          </Button>
          <Button onClick={() => setRegisterType('ecoplant')} className={registerType === 'ecoplant' ? 'selected' : ''}>
            <div className="image-section">
              <img src={EcoplantImage} alt="Ecoplant" />
            </div>
            <EcoPlantIcon /> <span>ecoPLANT</span>
          </Button>
        </div>
        <div className="continue-button">
          <PrimaryButton label="Continue" onClick={handleContinueClick} />
        </div>
      </div>
      <PlatformIssueReportingModal
        open={reportingModalOpen}
        setOpen={setReportingModalOpen}
        currentUser={currentUser}
      />
    </div>
  );
};

export default HardwareRegisterChooseTypeStep;
