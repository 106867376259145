import React from 'react';
import E401Image from '../images/page-401.png';
import Text from '../components/Text';
import SecondaryButton from '../components/buttons/SecondaryButton';
import { useNavigate } from 'react-router-dom';

const E401Page: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="page-404-403-container">
      <div className="wraper">
        <img src={E401Image} alt="error 401" />
        <Text variant="h2" className="tw-mb-3">
          Unauthorized!
        </Text>
        <Text variant="bodyLg">Sorry, you don’t have permission to access this page</Text>
        <div className=" tw-flex tw-justify-center tw-mt-6">
          <SecondaryButton label="Go Back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </div>
  );
};

export default E401Page;
