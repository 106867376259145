import React from 'react';

type Props = {
  serialCode: string;
};

const SerialCodeComponent: React.FC<Props> = ({ serialCode, ...props }) => {
  return (
    <div id="serial-code-component" {...props}>
      {serialCode}
    </div>
  );
};

export default SerialCodeComponent;
