import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { CommonStatus } from '../CommonStatus';
import { UserI } from '../../interfaces/user';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const RolesTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Role',
      dataIndex: 'name',
      width: 150,
      ellipsis: true,
      render: (name: string) => {
        return <span className="text-capitalize">{name}</span>;
      },
    },
    {
      title: 'Users',
      dataIndex: 'users',
      width: 150,
      ellipsis: true,
      render: (users: UserI[]) => {
        return users.length;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      ellipsis: true,
      render: (status: string) => {
        return <CommonStatus status={status} />;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default RolesTable;
