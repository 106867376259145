import { Form, message, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetAllocationHistory } from '../../apis/allocationHistories';
import AllocationHistoryInformation from '../../components/AllocationHistory/Information';
import AllocateeInformation from '../../components/AllocationHistory/AllocateeInformation';
import DetailsTopSection from '../../components/shared/DetailTopSection';
import { useMutation, useQueryClient } from 'react-query';
import { updateAllocationHistories } from '../../apis/hardwareAllocations';
import AddAttachmentForm from '../../components/forms/AddAttachmentForm';
import { ProductAttachmentCategories } from '../../constants';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import AllocationResources from '../../components/AllocationHistory/Resources';

const AllocationDetailsPage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const queryClient = useQueryClient();
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { data: allocationHistory, isLoading } = useGetAllocationHistory(uuid);
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'hardware-allocations');

  const { mutate } = useMutation(updateAllocationHistories, {
    onSuccess: async () => {
      message.info('Uploaded attachment!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Adding attachment failed! Please try again');
    },
  });

  function handleCancelUploadAttachment() {
    setIsVisibleModalUpload(false);
    form.resetFields(['categoryName', 'file']);
  }

  return (
    <Spin spinning={isLoading}>
      <div>
        <DetailsTopSection
          label="Allocation Report"
          setIsVisibleModalUpload={setIsVisibleModalUpload}
          access={access}
        />
        <AllocateeInformation allocationHistory={allocationHistory} />
        <AllocationHistoryInformation allocationHistory={allocationHistory} />
        <AllocationResources data={allocationHistory} />
        <Modal
          open={isVisibleModalUpload}
          onOk={handleCancelUploadAttachment}
          onCancel={handleCancelUploadAttachment}
          footer={null}
          width={560}
        >
          <AddAttachmentForm
            form={form}
            handleCancel={handleCancelUploadAttachment}
            multiple
            categories={ProductAttachmentCategories}
            update={mutate}
          />
        </Modal>
      </div>
    </Spin>
  );
};

export default AllocationDetailsPage;
