import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const HardwareIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 16 16"
      height={`${height ?? 16}px`}
      viewBox="0 0 16 16"
      width={`${width ?? 16}px`}
      fill={color ?? 'currentColor'}
    >
      <path
        id="Vector"
        d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V8C0.5 8.91667 1.25 9.66667 2.16667 9.66667H13.8333C14.75 9.66667 15.5 8.91667 15.5 8V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM2.16667 6.33333H4.775C4.95 6.98333 5.33333 7.55833 5.83333 8H2.16667V6.33333ZM13.8333 8H10.1667C10.6667 7.55833 11.05 6.98333 11.225 6.33333H13.8333V8ZM13.8333 4.66667H9.66667V5.5C9.66667 6.39167 8.89167 7.16667 8 7.16667C7.10833 7.16667 6.33333 6.39167 6.33333 5.5V4.66667H2.16667V2.16667H13.8333V4.66667ZM12.1667 10.5H9.66667V11.3333C9.66667 11.725 9.50833 12.0833 9.26667 12.375C8.95833 12.75 8.5 13 8 13C7.5 13 7.04167 12.75 6.73333 12.375C6.49167 12.0833 6.33333 11.725 6.33333 11.3333V10.5H0.5V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V10.5H12.1667ZM2.16667 12.1667H4.775C4.79167 12.2417 4.825 12.3083 4.85 12.375C5.05 12.9417 5.39167 13.4417 5.83333 13.8333H2.16667V12.1667ZM13.8333 13.8333H10.1667C10.6167 13.4417 10.9583 12.9417 11.15 12.375C11.175 12.3083 11.2083 12.2417 11.225 12.1667H13.8333V13.8333Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};

export default HardwareIcon;
