import React from 'react';
import E404Image from '../images/page-404.png';

const E404Page: React.FC = () => {
  return (
    <div className="page-404-403-container">
      <div className="wraper">
        <img src={E404Image} alt="error 404" />
        <h2>Page not Found!</h2>
        <p>Sorry, the page you’re looking for might have been removed or is temporarily unavailable.</p>
      </div>
    </div>
  );
};

export default E404Page;
