import React, { ReactNode } from 'react';
// import './ScanQRCode.scss';
import FillingScanTabsContent from './ScanTabsContent';
import { ScanCode } from '../shared/Scanner';
import Text from '../Text';

type Props = {
  toteScanned?: { serialCode: string }[];
  scanNoticeMessage: string | ReactNode;
  stopDecodingScanner: boolean;
  handleScanOrManualSubmit: (result: any) => void;
  setStopDecodingScanner: (_: any) => void;
};

const FillingEcoToteScanQRCode: React.FC<Props> = ({
  toteScanned,
  scanNoticeMessage,
  stopDecodingScanner,
  setStopDecodingScanner,
  handleScanOrManualSubmit,
}) => {
  return (
    <div className="component-hardware-filling-scan-qrcode">
      <Text variant="h2" className="tw-w-full tw-text-center">
        Scan by QR/Bar Code
      </Text>
      <FillingScanTabsContent toteScanned={toteScanned} scanNoticeMessage={scanNoticeMessage} />
      <ScanCode
        stopDecodingScanner={stopDecodingScanner}
        setStopDecodingScanner={setStopDecodingScanner}
        handleScanOrManualSubmit={handleScanOrManualSubmit}
      />
    </div>
  );
};

export default FillingEcoToteScanQRCode;
