import React, { useState } from 'react';
import {
  HardwareItemI,
  // HardwareItemI,
  HardwareScannedStateI,
  // HardwareTypeI,
  // HardwareVerifyCollectionI,
  // HardwareVerifyTypeI,
} from '../../interfaces/hardware';
import { HardwareProgressStepListI } from '../../interfaces';
import SharedHardwareProgressBar from '../../components/shared/Hardware/ProgressBar';
import useNavigateAway from '../../hooks/useNavigateAway';
import { useNavigate } from 'react-router-dom';
import { useAppNotification } from '../../hooks/useAppNotification';
import TransferToScreen from '../../components/Transfer/TransferToScreen';
import { PartnerI } from '../../interfaces/partner';
import TransferScanStep from '../../components/Transfer/ScanStep';
import { doVerifyTransfer } from '../../apis/transfers';
import Text from '../../components/Text';
import { TransferVerifyResponseI } from '../../interfaces/transfer';
import { handleRemoveVerifiedData } from '../../utils/hardware';
import HardwareVerifyFilterForm from '../../components/forms/hardware/VerifyFilter';
import TransferVerifyStep from '../../components/Transfer/VerifyStep';
import TransferReviewStep from '../../components/Transfer/ReviewStep';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'Transfer To',
  },
  {
    step: 2,
    label: 'Scan',
  },
  {
    step: 3,
    label: 'Verify',
  },
  {
    step: 4,
    label: 'Review',
  },
];

const TransferNewPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [scannedData, setScannedData] = useState<HardwareScannedStateI[]>([]);
  const [verifiedData, setVerifiedData] = useState<TransferVerifyResponseI>();
  const [filtered, setFiltered] = useState<TransferVerifyResponseI>();
  const [successData, setSuccessData] = useState();
  const [finalScannedData, setFinalScannedData] = useState<HardwareScannedStateI[]>([]);
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [partner, setPartner] = useState<PartnerI | undefined>();
  const { openNotification } = useAppNotification();
  console.log('verifiedData', verifiedData);

  useNavigateAway({
    when: finalScannedData.length > 0 || scannedData.length > 0,
    handleProceed() {
      setScannedData([]);
      setFinalScannedData([]);
      setStopDecodingScanner(true);
      setPartner(undefined);
    },
  });

  const handleVerify = async () => {
    try {
      const finalData = [...finalScannedData, ...scannedData];
      const params = {
        hardwareList: finalData,
      } as {
        hardwareList: HardwareScannedStateI[];
      };
      setFinalScannedData(finalData);
      setScannedData([]);
      const data = await doVerifyTransfer(params);
      if (data) {
        openNotification({
          type: data?.numberOfInvalid ? 'warning' : 'success',
          title: (
            <div>
              <span>You have scanned {} Code(s) that includes:</span>
              <ul className="tw-my-1 tw-px-6 tw-pl-[25px]">
                <li>{data.numberOfHardwareScanned} hardware</li>
                {data?.numberOfInvalid ? <li>{data.numberOfInvalid} invalid code(s)</li> : ''}
              </ul>
            </div>
          ),
          content: data?.numberOfInvalid ? (
            <Text variant="bodyMd">Please remove invalid code(s) before proceeding to next step!</Text>
          ) : (
            ''
          ),
        });
      }
      setVerifiedData(data);
      setCurrentStep(3);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyFilter = (values: { serialCode: HardwareScannedStateI['serialCode'] }) => {
    if (values?.serialCode) {
      const hardwareList = verifiedData?.hardwareList?.filter(
        (item: HardwareScannedStateI) => item.serialCode === values.serialCode,
      );
      const newData: any = { ...verifiedData };
      newData.hardwareList = hardwareList;
      setFiltered(newData);
    } else {
      setFiltered(undefined);
    }
  };

  const handleDeleteVerifiedData = (item: HardwareItemI) => {
    const result = handleRemoveVerifiedData(verifiedData, item.serialCode);
    setVerifiedData(result);
    setFinalScannedData((prevItems) => {
      const index = finalScannedData.findIndex((data) => data.serialCode === item.serialCode);
      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }
      return prevItems;
    });
  };

  const handleScanmoreButton = () => {
    setCurrentStep(2);
    setStopDecodingScanner(false);
  };

  const backFunction = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
    if (currentStep === 1) navigate(-1);
  };

  return (
    <div id="pages-hardware-depletion-new-container">
      <SharedHardwareProgressBar
        currentStep={currentStep}
        title="Ownership Transfer"
        stepList={progressStepList}
        backFunction={backFunction}
      />
      {currentStep === 3 && (
        <HardwareVerifyFilterForm
          handleVerifyFilter={handleVerifyFilter}
          numberOfHardwareScanned={verifiedData?.numberOfHardwareScanned}
          numberOfInvalid={verifiedData?.numberOfInvalid}
        />
      )}
      {currentStep === 1 && (
        <TransferToScreen setPartner={setPartner} partner={partner} setCurrentStep={setCurrentStep} />
      )}
      {currentStep === 2 && (
        <TransferScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setScannedData}
          scannedData={scannedData}
          handleVerify={handleVerify}
          setStopDecodingScanner={setStopDecodingScanner}
          stopDecodingScanner={stopDecodingScanner}
          finalScannedData={finalScannedData}
          setFinalScannedData={setFinalScannedData}
        />
      )}
      {currentStep === 3 && (
        <TransferVerifyStep
          setCurrentStep={setCurrentStep}
          verifiedData={filtered ?? verifiedData}
          handleDeleteVerifiedData={handleDeleteVerifiedData}
          handleScanmoreButton={handleScanmoreButton}
          nextStep={4}
        />
      )}
      {currentStep === 4 && (
        <TransferReviewStep
          setScannedData={setScannedData}
          setCurrentStep={setCurrentStep}
          verifiedData={verifiedData}
          setSuccessData={setSuccessData}
          successData={successData}
          setFinalScannedData={setFinalScannedData}
          previousStep={3}
          partner={partner}
          setPartner={setPartner}
        />
      )}
    </div>
  );
};

export default TransferNewPage;
