import React, { Fragment } from 'react';
import {
  HardwareGroupI,
  HardwareI,
  HardwareItemI,
  HardwareTypeAPII,
  HardwareTypeI,
  HardwareVerifyCollectionI,
  HardwareVolumeI,
} from '../interfaces/hardware';
import EcoPlantIcon from '../svgs/hardwareTypes/EcoPlantIcon';
import RightArrowIcon from '../svgs/RightArrowIcon';
import SmartPOURIcon from '../svgs/hardwareTypes/SmartPOURIcon';
import NoticeIcon from '../svgs/NoticeIcon';
import EcoTOTEIcon from '../svgs/hardwareTypes/EcoTOTEIcon';
import Text from '../components/Text';
import Icon from '../components/Icon';
import { DateFormat } from './global';
import NetworkStatus from '../components/shared/NetworkStatus';

export const renderNoticeContentByType = (type: HardwareTypeI) => {
  switch (type) {
    case 'ecoplant':
      return (
        <Fragment>
          <NoticeIcon />
          <span>Barcode can be found near the display screen of the Filling Machine in the ecoPLANT.</span>
        </Fragment>
      );
    case 'smartpour':
      return (
        <Fragment>
          <NoticeIcon />
          <span>
            Bar Code can be found either on the underside of the SmartPour 1.1S, or underside of the cover inside the
            SmartPOUR 2.0S.
          </span>
        </Fragment>
      );
    case 'ecotote1.75l':
      return (
        <Fragment>
          <NoticeIcon />
          <span>Barcode can be found on the outer casing of the ecoTOTE.</span>
        </Fragment>
      );
    case 'ecotote4.5l':
      return (
        <Fragment>
          <NoticeIcon />
          <span>Barcode can be found on the outer casing of the ecoTOTE.</span>
        </Fragment>
      );
    default:
      return '';
  }
};

export const handleDisplayHardwareType = (type: string | undefined) => {
  if (!type) return '';
  switch (type) {
    case 'ecoplant':
      return 'ecoPLANT';
    case 'smartpour':
      return 'SmartPour';
    case 'ecotote1.75l':
      return 'ecoTOTE 1.75L';
    case 'ecotote4.5l':
      return 'ecoTOTE 4.5L';
    default:
      return '';
  }
};

type RenderScannedButtonByTypeOptions = {
  width?: number;
  height?: number;
  color?: string;
  isShowRightArror?: boolean;
};

export const renderScannedButtonByType = (
  type: HardwareTypeI,
  scannedNo?: number,
  options?: RenderScannedButtonByTypeOptions,
) => {
  switch (type) {
    case 'ecoplant':
      return (
        <div className="tw-flex tw-gap-2 tw-items-center">
          <EcoPlantIcon
            width={options?.width ?? 20}
            height={options?.height ?? 20}
            color={options?.color ?? '#898989'}
          />
          <span>{`${scannedNo} ${handleDisplayHardwareType(type)} Scanned`}</span>
          {options?.isShowRightArror && <RightArrowIcon />}
        </div>
      );
    case 'smartpour':
      return (
        <div className="tw-flex tw-gap-2 tw-items-center">
          <SmartPOURIcon
            width={options?.width ?? 20}
            height={options?.height ?? 20}
            color={options?.color ?? '#898989'}
          />
          <span>{`${scannedNo} ${handleDisplayHardwareType(type)} Scanned`}</span>
          {options?.isShowRightArror && <RightArrowIcon />}
        </div>
      );
    case 'ecotote1.75l':
      return (
        <div className="tw-flex tw-gap-2 tw-items-center">
          <EcoTOTEIcon
            width={options?.width ?? 20}
            height={options?.height ?? 20}
            color={options?.color ?? '#898989'}
          />
          <span>{`${scannedNo} ${handleDisplayHardwareType(type)} Scanned`}</span>
          {options?.isShowRightArror && <RightArrowIcon />}
        </div>
      );
    case 'ecotote4.5l':
      return (
        <div className="tw-flex tw-gap-2 tw-items-center">
          <EcoTOTEIcon
            width={options?.width ?? 20}
            height={options?.height ?? 20}
            color={options?.color ?? '#898989'}
          />
          <span>{`${scannedNo} ${handleDisplayHardwareType(type)} Scanned`}</span>
          {options?.isShowRightArror && <RightArrowIcon />}
        </div>
      );
    default:
      return '';
  }
};

export const handleDisplayIconByType = (type: HardwareTypeI, options?: RenderScannedButtonByTypeOptions) => {
  if (!type) return '';
  switch (type) {
    case 'ecoplant':
      return (
        <EcoPlantIcon width={options?.width ?? 20} height={options?.height ?? 20} color={options?.color ?? '#191919'} />
      );
    case 'smartpour':
      return (
        <SmartPOURIcon
          width={options?.width ?? 20}
          height={options?.height ?? 20}
          color={options?.color ?? '#191919'}
        />
      );
    case 'ecotote1.75l':
      return (
        <EcoTOTEIcon width={options?.width ?? 20} height={options?.height ?? 20} color={options?.color ?? '#191919'} />
      );
    case 'ecotote4.5l':
      return (
        <EcoTOTEIcon width={options?.width ?? 20} height={options?.height ?? 20} color={options?.color ?? '#191919'} />
      );
    default:
      return '';
  }
};

export const convertToHardwareType = (type: string) => {
  switch (type) {
    case 'ecoplant':
      return 'ecoPLANT';
    case 'smartpour':
      return 'SmartPour';
    case 'ecotote':
      return 'ecoTOTE';
    case 'smartkiosk':
      return 'SmartKiosk';
    default:
      return type;
  }
};

export const convertToHardwareTypeAPI = (
  type: HardwareTypeI,
): { type: HardwareTypeAPII; volume?: HardwareVolumeI } | undefined => {
  switch (type) {
    case 'ecoplant':
      return { type: 'ecoplant' };
    case 'smartpour':
      return { type: 'smartpour' };
    case 'ecotote1.75l':
      return { type: 'ecotote', volume: '1.75L' };
    case 'ecotote4.5l':
      return { type: 'ecotote', volume: '4.5L' };
  }
};
export const getTypeFromApi = (hardware?: Partial<HardwareItemI> & Partial<HardwareGroupI>) => {
  if (!hardware) return '';
  switch (hardware.type ?? hardware.hardwareType) {
    case 'ecoplant':
      return 'ecoPLANT';
    case 'smartpour':
      return 'SmartPour';
    case 'ecotote':
      if (hardware.volume === '1.75L' || hardware.toteVolume === '1.75L' || hardware.ecoTote?.volume === '1.75L')
        return 'ecoTOTE 1.75L';
      if (hardware.volume === '4.5L' || hardware.toteVolume === '4.5L' || hardware.ecoTote?.volume === '4.5L')
        return 'ecoTOTE 4.5L';
      return 'ecoTOTE';
    default:
      return '';
  }
};

export const getTrackingTypeFromApi = (hardware: HardwareItemI | HardwareI) => {
  if (!hardware) return '';
  switch (hardware.type) {
    case 'ecoplant':
      return 'ecoPLANT';
    case 'smartpour':
      return 'SmartPour';
    case 'ecotote':
      if (hardware?.ecoTote?.volume === '1.75L') return 'ecoTOTE 1.75L';
      if (hardware?.ecoTote?.volume === '4.5L') return 'ecoTOTE 4.5L';
      return 'ecoTOTE';
    default:
      return '';
  }
};

export const handleDisplayIconByTypeFromApi = (type?: string, options?: RenderScannedButtonByTypeOptions) => {
  if (!type) return '';
  switch (type) {
    case 'ecoplant':
      return (
        <EcoPlantIcon width={options?.width ?? 20} height={options?.height ?? 20} color={options?.color ?? '#898989'} />
      );
    case 'smartpour':
      return (
        <SmartPOURIcon
          width={options?.width ?? 20}
          height={options?.height ?? 20}
          color={options?.color ?? '#898989'}
        />
      );
    case 'ecotote':
      return (
        <EcoTOTEIcon width={options?.width ?? 20} height={options?.height ?? 20} color={options?.color ?? '#898989'} />
      );
    default:
      return '';
  }
};

export const getTrackingLastFilledProduct = (data: HardwareI) => {
  switch (data.type) {
    case 'ecotote':
      return data?.ecoTote?.product?.brandName;
    case 'smartPour':
      return data?.smartPour?.batteryLife;
    default:
      return '';
  }
};

export const getTrackingVolumn = (data: HardwareI) => {
  if (data.type === 'ecotote') return data?.ecoTote?.volume;
};

export const getEcototeVolumn = (type: string) => {
  switch (type) {
    case 'ecotote1.75l':
      return '1.75L';
    case 'ecotote4.5l':
      return '4.5L';
    default:
      return '';
  }
};

export const getEcototeWithVolumnFromApi = (hardware?: Partial<HardwareItemI>) => {
  if (!hardware) return '';
  switch (hardware.type) {
    case 'ecoplant':
      return 'ecoPLANT';
    case 'smartpour':
      return 'SmartPour';
    case 'ecotote':
      return `ecoTOTE ${hardware.ecoTote?.volume}`;
    default:
      return '';
  }
};

export const handleRemoveVerifiedData = (verifiedData?: HardwareVerifyCollectionI, deleteItem: string = '') => {
  if (!verifiedData) return undefined;
  let hardwares: HardwareItemI[] = [];
  verifiedData?.hardwareList?.forEach((item) => {
    if (deleteItem !== item.serialCode) {
      hardwares = [...hardwares, item];
    }
  });

  const total: number = hardwares.length ?? 0;
  let invalidNo: number = 0;
  let validNo: number = 0;
  hardwares.forEach((item) => {
    if (item?.error) {
      invalidNo = total - 1;
    }
  });
  validNo = total - invalidNo;

  return {
    hardwareList: hardwares,
    numberOfHardwareScanned: total,
    numberOfInvalid: invalidNo,
    numberOfValid: validNo,
  };
};

export const renderInfoByType = (item: Partial<HardwareItemI> | undefined) => {
  if (!item) return '';
  return (
    <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
      {item?.type === 'ecotote' && (
        <>
          <Icon name="icon-water-drop" size={12} className=" tw-text-grey-700" />
          <span>
            {item?.ecoTote?.lastKnownVolume !== null
              ? (Number(item?.ecoTote?.lastKnownVolume) / 1000).toFixed(2)
              : (0).toFixed(2)}
            L
          </span>
        </>
      )}
      {item?.type === 'ecoplant' && (
        <>
          <Icon name="icon-date_range" size={12} className=" tw-text-grey-700" />
          <span>{item?.ecoPlant?.lastFilledDate ? DateFormat(item?.ecoPlant?.lastFilledDate) : ''}</span>
        </>
      )}
      {item?.type === 'smartpour' && <NetworkStatus status={item?.smartPour?.network ?? null} />}
    </Text>
  );
};

export const handleGetUniqType = (hardwareList: HardwareItemI[]): any => {
  const typeCountMap = new Map<string, number>();

  hardwareList?.forEach((item: HardwareItemI) => {
    const type = getEcototeWithVolumnFromApi(item.currentData);
    if (typeCountMap.has(type)) {
      typeCountMap.set(type, typeCountMap.get(type)! + 1);
    } else {
      typeCountMap.set(type, 1);
    }
  });

  const result = Array.from(typeCountMap, ([type, count]) => ({ type, count }));

  return result;
};

export const displayAllHardwareHistoryType: Record<string, string> = {
  allocationReceive: 'Allocation (Recieve)',
  allocationSend: 'Allocation (Send)',
  cleaning: 'Cleaning',
  vsr: 'VSR',
  depletion: 'Deplete',
  dispense: 'Dispense',
  filling: 'Fill',
  addGroup: 'Hardware Group',
  removeGroup: 'Hardware Group',
  mating: 'Mating',
  online: 'Network Status',
  offline: 'Network Status',
  pairing: 'Pairing',
  priming: 'Priming',
  registered: 'Registration',
  updateStatus: 'Status Change',
};

export const displayEcototeHistoryType: Record<string, string> = {
  allocationReceive: 'Allocation (Recieve)',
  allocationSend: 'Allocation (Send)',
  depletion: 'Deplete',
  filling: 'Fill',
  addGroup: 'Hardware Group',
  mating: 'Mating',
  registered: 'Registration',
  updateStatus: 'Status Change',
};

export const displaySmartPourHistoryType: Record<string, string> = {
  allocationReceive: 'Allocation (Recieve)',
  allocationSend: 'Allocation (Send)',
  cleaning: 'Cleaning',
  vsr: 'VSR',
  depletion: 'Deplete',
  filling: 'Fill',
  addGroup: 'Hardware Group',
  mating: 'Mating',
  online: 'Network Status',
  dispense: 'Dispense',
  pairing: 'Pairing',
  priming: 'Priming',
  registered: 'Registration',
  updateStatus: 'Status Change',
};

export const displayEcoPlantHistoryType: Record<string, string> = {
  allocationReceive: 'Allocation (Recieve)',
  allocationSend: 'Allocation (Send)',
  cleaning: 'Cleaning',
  dispense: 'Dispense',
  filling: 'Fill',
  addGroup: 'Hardware Group',
  mating: 'Mating',
  online: 'Online',
  priming: 'Priming',
  registered: 'Registration',
  updateStatus: 'Status Change',
};

export const getHistoryTypeByType = (type: string) => {
  let historyType = undefined;
  if (type === 'ecotote') {
    historyType = displayEcototeHistoryType;
  } else if (type === 'smartpour') {
    historyType = displaySmartPourHistoryType;
  } else if (type === 'ecoplant') {
    historyType = displayEcoPlantHistoryType;
  } else {
    historyType = displayAllHardwareHistoryType;
  }

  return historyType;
};

export const convertVolumeTo2DP = (volume: string) => {
  if (volume === null || volume === undefined) return '0.00L';
  const volumeNo = Number(volume.split('L')[0] ?? 0);
  return volumeNo.toFixed(2) + 'L';
};
