import { Dropdown, MenuProps, Space, Tabs, TabsProps } from 'antd';
import React, { useMemo } from 'react';
import Text from '../../components/Text';
import ProductsTabPage from './ProductsTab';
import ProductGroupsTabPage from './GroupsTab';
import { CurrentUserI } from '../../interfaces/auth';
import { Link, useOutletContext } from 'react-router-dom';
import { handleAccessControl } from '../../utils/role';
import Icon from '../../components/Icon';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';

const createTabItem = (key: string, label: string, children: React.ReactNode) => ({
  key,
  label: (
    <Text variant="labelLg" className="tab-title !tw-font-normal">
      <span>{label}</span>
    </Text>
  ),
  children,
});

const handleGetTabItems = (currentUser: CurrentUserI) => {
  const productAccess = handleAccessControl(currentUser, 'products');
  const groupAccess = handleAccessControl(currentUser, 'product-groups');

  const tabItems: TabsProps['items'] = [
    ...(productAccess.isCanRead ? [createTabItem('1', 'All Products', <ProductsTabPage />)] : []),
    ...(groupAccess.isCanRead ? [createTabItem('2', 'Product Group', <ProductGroupsTabPage />)] : []),
  ];

  return tabItems;
};

const handleGetActionItems = (currentUser: CurrentUserI) => {
  const productAccess = handleAccessControl(currentUser, 'products');
  const groupAccess = handleAccessControl(currentUser, 'product-groups');

  const createAllocationItems: MenuProps['items'] = [
    productAccess.isCanCreate
      ? {
          label: (
            <Link to="/products/new">
              <Icon name="icon-add_box" /> <span>Create Product</span>
            </Link>
          ),
          key: '0',
        }
      : null,
    groupAccess.isCanCreate
      ? {
          label: (
            <Link to="/product-groups/new">
              <Icon name="icon-group_work" /> <span>Create Product Grouping</span>
            </Link>
          ),
          key: '1',
        }
      : null,
  ];
  return createAllocationItems;
};

const ProductsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const actionItems = useMemo(() => handleGetActionItems(currentUser), []);
  const tabItems = useMemo(() => handleGetTabItems(currentUser), []);

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
        <Text component="h3" variant="h3">
          Product List
        </Text>
        <Dropdown menu={{ items: actionItems }} trigger={['click']} overlayClassName="create-allocation-dropdown">
          <Space>
            <PrimaryButton label="Add Action" icon={<PlusIcon />} />
          </Space>
        </Dropdown>
      </div>
      <Tabs defaultActiveKey="1" items={tabItems} rootClassName="app-tabs" className="tw-mt-2" />
    </div>
  );
};

export default ProductsPage;
