import React from 'react';
import { Tabs, TabsProps } from 'antd';
import Icon from '../../Icon';
import Text from '../../Text';
import HardwareAttachments from './Attachments';
import PlatformHardwareHistory from './History';
import { HardwareI } from '../../../interfaces/hardware';

type Props = {
  data: HardwareI;
  id?: string;
};

const PlatformHardwareResources: React.FC<Props> = ({ data, id }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-history" size={20} /> <span>History</span>
        </Text>
      ),
      children: <PlatformHardwareHistory id={id} hardwareType={data?.type} />,
    },
    {
      key: '4',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-attach_file" /> <span>Attachments</span>
        </Text>
      ),
      children: <HardwareAttachments attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="hardware-attachment-container" style={{ marginBottom: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="app-tabs" />
      </div>
    </div>
  );
};

export default PlatformHardwareResources;
