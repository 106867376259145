import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const InformationIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 16}px`}
      viewBox="0 0 16 16"
      width={`${width ?? 16}px`}
      fill={color ?? '#4CB4E7'}
    >
      <g id="information" clipPath="url(#clip0_13524_41600)">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5ZM7 7C6.58579 7 6.25 7.33579 6.25 7.75C6.25 8.16421 6.58579 8.5 7 8.5H7.25V10.5H6.25C5.83579 10.5 5.5 10.8358 5.5 11.25C5.5 11.6642 5.83579 12 6.25 12H8H9.75C10.1642 12 10.5 11.6642 10.5 11.25C10.5 10.8358 10.1642 10.5 9.75 10.5H8.75V7.75V7H8H7Z"
          fill={color ?? '#4CB4E7'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13524_41600">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default InformationIcon;
