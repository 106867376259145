import React from 'react';
import './HistoryAndAttachment.scss';
import { Tabs, TabsProps } from 'antd';
import TrackingHistory from './History';
import Icon from '../Icon';
import Text from '../Text';
import TrackingAttachments from './Attachments';
import TrackingDetailGroups from './Groups';
import { HardwareI } from '../../interfaces/hardware';

type Props = {
  data: HardwareI;
  id?: string;
};

const TrackingResources: React.FC<Props> = ({ data, id }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-history" size={20} /> <span>History</span>
        </Text>
      ),
      children: <TrackingHistory id={id} hardwareType={data?.type} />,
    },
    {
      key: '2',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-group_work" size={20} /> <span>Groups</span>
        </Text>
      ),
      children: <TrackingDetailGroups data={data} />,
    },
    // {
    //   key: '2',
    //   label: (
    //     <Text variant="labelLg" className="tab-title">
    //       <Icon name="icon-build_circle" /> <span>Configuration</span>
    //     </Text>
    //   ),
    //   children: <TrackingHistory data={data} />,
    // },
    // {
    //   key: '3',
    //   label: (
    //     <Text variant="labelLg" className="tab-title">
    //       <Icon name="icon-error_outline" /> <span>Fault Report</span>
    //     </Text>
    //   ),
    //   children: <TrackingHistory data={data} />,
    // },
    {
      key: '4',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-attach_file" /> <span>Attachments</span>
        </Text>
      ),
      children: <TrackingAttachments attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="tracking-hardware-attachment-container" style={{ marginBottom: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="app-tabs" />
      </div>
    </div>
  );
};

export default TrackingResources;
