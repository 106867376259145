import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import Logo from '../../images/qrcode-logo.png';

type Props = {
  data: string;
};

export const DisplayQRCode: React.FC<Props> = ({ data }) => {
  return (
    <QRCode
      value={data}
      size={100}
      style={{ border: '1px solid #DAD9D9', borderRadius: 4 }}
      logoImage={Logo}
      logoWidth={28}
    />
  );
};

export default DisplayQRCode;
