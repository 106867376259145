import React, { ReactNode } from 'react';
import '../shared/Hardware/StartScanningScreen.scss';
import { Notice } from '../Notice';
import WarningIcon from '../../svgs/WarningIcon';
import SizeBox from '../SizeBox';
import PrimaryButton from '../buttons/PrimaryButton';

interface Props {
  warningDescription: string | ReactNode;
  warningDescription2?: string | ReactNode;
  setScreen: (screen: 'start' | 'scan' | 'scanned') => void;
  setStopDecodingScanner: (_: any) => void;
}

export const FillingStartScanningScreen: React.FC<Props> = ({
  warningDescription2,
  warningDescription,
  setScreen,
  setStopDecodingScanner,
}) => {
  const onStartScanning = () => {
    setStopDecodingScanner(false);
    setScreen('scan');
  };
  return (
    <div className="start-scanning-screen-component">
      <Notice description={warningDescription} type="warning" icon={<WarningIcon />} closable={false} />
      <SizeBox height={24} />
      {warningDescription2 && (
        <Notice description={warningDescription2} type="warning" icon={<WarningIcon />} closable={false} />
      )}
      <SizeBox height={24} />
      <div className="button-acton">
        <PrimaryButton label="Start Scanning" onClick={onStartScanning} />
      </div>
    </div>
  );
};
