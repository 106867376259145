import { Drawer, Form, FormInstance, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { CurrentUserI } from '../../interfaces/auth';
import { convertToApiFile } from '../../utils/global';
import { AttachmentI } from '../../interfaces/attachments';
import UploadAttachmentButton from '../buttons/UploadAttachmentButton';
import { AttachmentCategories } from '../../constants';
import { PhoneNumberInput } from '../PhoneNumberInput/PhoneNumberInput';
import SecondaryButton from '../buttons/SecondaryButton';
import Text from '../Text';
import Countdown from '../CountDown';
import PrimaryButton from '../buttons/PrimaryButton';
import { Notice } from '../Notice';
import WarningIcon from '../../svgs/WarningIcon';
import SizeBox from '../SizeBox';
import { useMutation, useQueryClient } from 'react-query';
import { doEmailRetrigger } from '../../apis/partners';
import Divider from '../Divider';

const RenderDescriptionNotice = () => {
  return (
    <>
      <p>This user is the Partner's Administrator. </p>
      <p>Updating this user's email will change the Partner's Administrative Email upon verification.</p>
    </>
  );
};

type Props = {
  form: FormInstance;
  profile?: any;
  currentUser: CurrentUserI;
  isUpdated: boolean;
  setIsUpdated: (_: boolean) => void;
  handleSubmit(_: any): void;
};

const ProfileForm: React.FC<Props> = ({ isUpdated, form, profile, currentUser, handleSubmit, setIsUpdated }) => {
  const queryClient = useQueryClient();
  const [files, setFiles] = useState<any[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isStartCountdown, setIsStartCountdown] = useState<boolean>(false);
  const [retriggerEmail, setRetriggerEmail] = useState<string>();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setIsUpdated(false);
  };

  const handleOnFinish = (values: Partial<CurrentUserI>) => {
    const newValues: any = values;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    newValues.newEmail = values.newEmail;
    delete newValues.email;
    handleSubmit(newValues);
  };

  // const handleOnDrawerFinish = (values: Partial<CurrentUserI>) => {
  //   const newValues: any = values;
  //   if (files && Object.keys(files).length > 0) {
  //     if (files?.[0]?.data) {
  //       const file: any = files[0];
  //       const newFile = convertToApiFile(file);
  //       newValues.attachments = [newFile];
  //     }
  //   }

  //   newValues.newEmail = values.newEmail;
  //   handleSubmit(newValues);
  // };

  const { mutate: emailRetriggerMutate } = useMutation(doEmailRetrigger, {
    onSuccess: async () => {
      notification.info({
        message: 'Resend verification email!',
        placement: 'top',
      });
      queryClient.invalidateQueries();
    },
    onError: () => {
      notification.info({
        message: 'Your account has already been activated! Please check with your Partner admin.',
        placement: 'top',
      });
    },
  });

  useEffect(() => {
    if (profile) {
      let newAttachments;
      if (profile?.attachments && Object.values(profile.attachments).length > 0) {
        newAttachments = profile?.attachments.map((attachment: AttachmentI) => {
          return {
            categoryName: attachment.categoryName,
            id: attachment.id,
            name: attachment.fileName,
            type: attachment.fileType,
            url: attachment.url,
          };
        });
      }
      form.setFieldsValue({
        ...profile,
        newEmail: '',
      });
      setFiles(newAttachments ?? []);
      setRetriggerEmail(profile.newEmail ?? profile.email ?? '');
    }
  }, [profile]);

  const onEmailRetriggerSubmit = () => {
    setIsStartCountdown(true);
    emailRetriggerMutate(profile.email ?? '');
  };

  return (
    <div className="user-form-container">
      <Form
        form={form}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
        layout="vertical"
      >
        <div className="form-container">
          <div className="form-content">
            <div className="content-wrap">
              <div className="partner-entity-wrap">
                <p className="label">Partner Entity</p>
                <p className="value text-capitalize">{currentUser?.type}</p>
              </div>
              <h2 className="form-title">Profile Information</h2>
              <div className="flex-2-column">
                <Form.Item
                  label="User Name"
                  name="username"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Input placeholder="Type here" />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="contactNumber"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <PhoneNumberInput />
                </Form.Item>
              </div>
              <div className="flex-2-column">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { type: 'email', message: 'Invalid Email' },
                    { required: true, message: 'This field is required' },
                  ]}
                >
                  <Input type="email" placeholder="Type here" autoComplete="off" disabled />
                </Form.Item>
                <div></div>
              </div>
              <SecondaryButton
                label={<Text variant="buttonSm">Update Email</Text>}
                onClick={showDrawer}
                className="!tw-py-[5px] !tw-rounded"
              />
              <div className="attachment-list-section">
                <h2 className="form-title">Upload File</h2>
                <Form.Item name="uploadFile" label="Upload Attachment" style={{ width: '100%' }}>
                  <UploadAttachmentButton
                    categories={AttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Drawer
        title={<Text variant="h3">Update Email</Text>}
        onClose={onCloseDrawer}
        open={openDrawer}
        rootClassName="common-drawer-container"
        width={462}
      >
        {!isUpdated ? (
          <Form
            form={form}
            onFinish={handleOnFinish}
            scrollToFirstError
            autoComplete="off"
            initialValues={{ isActive: true }}
            layout="vertical"
          >
            <div className="form-container">
              <div className="form-content !tw-mt-0">
                {profile?.type === 'partnerAdmin' && (
                  <>
                    <Notice
                      description={<RenderDescriptionNotice />}
                      type="warning"
                      icon={<WarningIcon />}
                      closable={false}
                    />
                    <SizeBox height={24} />
                  </>
                )}
                <Form.Item
                  label="Current Email"
                  name="email"
                  rules={[
                    { type: 'email', message: 'Invalid Email' },
                    { required: true, message: 'This field is required' },
                  ]}
                >
                  <Input type="email" placeholder="Type here" autoComplete="off" disabled />
                </Form.Item>
                <Form.Item
                  label="New Email"
                  name="newEmail"
                  rules={[
                    { type: 'email', message: 'Invalid Email' },
                    { required: true, message: 'This field is required' },
                  ]}
                >
                  <Input type="email" placeholder="Type here" autoComplete="off" />
                </Form.Item>
                <PrimaryButton label="Update Email" className="tw-w-full tw-mt-8" htmlType="submit" />
              </div>
            </div>
          </Form>
        ) : (
          <>
            <Text variant="bodyLg">
              A verification email has been sent to:
              <span className="tw-text-primary-500"> {retriggerEmail}</span>
            </Text>
            <Text variant="bodyLg">
              For the update of Administrator's Email to be effective, please ensure that the new email address is
              verified and a new password is set up thereafter.
            </Text>
            <SizeBox height={24} />
            <Text variant="bodyLg">
              If the user is unable to find the verification email in his/her inbox, kindly advise them to check their
              Spam folder.
            </Text>
            {isStartCountdown && (
              <p>
                Alternatively, you can also try sending another verification email in{' '}
                {
                  <Countdown
                    isStartCountdown={isStartCountdown}
                    totalOfTime={29}
                    setIsStartCountdown={setIsStartCountdown}
                  />
                }
              </p>
            )}
            <SecondaryButton
              label="Resend Verification Email"
              onClick={onEmailRetriggerSubmit}
              className="tw-w-full tw-mt-8"
              disabled={isStartCountdown}
            />
            <SizeBox height={24} />
            <Divider />
            <PrimaryButton label="Close" onClick={onCloseDrawer} className="tw-w-full tw-mt-8" />
          </>
        )}
      </Drawer>
    </div>
  );
};

export default ProfileForm;
