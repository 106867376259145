import { Checkbox, Form } from 'antd';
import React, { Fragment, useMemo } from 'react';
import { ModuleI } from '../../../interfaces/module';
import { CheckboxOptionI } from '../../../interfaces';
import ActionSection from '../../Role/ActionSection';
import { handleGetPermissionFromModule } from '../../../utils/role';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const HardwareCollapse: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  console.log('modules', modules);

  const hardwareCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Hardware'),
    [modules],
  );
  const registrationCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Registration'),
    [modules],
  );
  const allocationCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Allocation'),
    [modules],
  );
  const depletionCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Depletion'),
    [modules],
  );
  const fillingCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Filling'),
    [modules],
  );

  const groupCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Hardware_grouping'),
    [modules],
  );

  const transferCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Transfer'),
    [modules],
  );

  return (
    <Fragment>
      <ActionSection
        label="Tracking"
        content={
          <Form.Item name="colHardware">
            <Checkbox.Group options={hardwareCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colHardware')} />
          </Form.Item>
        }
      />
      <ActionSection
        label="Hardware Grouping"
        content={
          <Form.Item name="colHardware_grouping">
            <Checkbox.Group
              options={groupCheckboxOptions}
              onChange={(e) => onCheckboxChange(e, 'colHardware_grouping')}
            />
          </Form.Item>
        }
      />
      <ActionSection
        label="Registration"
        content={
          <Form.Item name="colRegistration">
            <Checkbox.Group
              options={registrationCheckboxOptions}
              onChange={(e) => onCheckboxChange(e, 'colRegistration')}
            />
          </Form.Item>
        }
      />
      <ActionSection
        label="Allocation"
        content={
          <Form.Item name="colAllocation">
            <Checkbox.Group
              options={allocationCheckboxOptions}
              onChange={(e) => onCheckboxChange(e, 'colAllocation')}
            />
          </Form.Item>
        }
      />
      <ActionSection
        label="Transfer"
        content={
          <Form.Item name="colTransfer">
            <Checkbox.Group options={transferCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colTransfer')} />
          </Form.Item>
        }
      />
      <ActionSection
        label="Fill"
        content={
          <Form.Item name="colFilling">
            <Checkbox.Group options={fillingCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colFilling')} />
          </Form.Item>
        }
      />
      <ActionSection
        label="Deplete"
        content={
          <Form.Item name="colDepletion">
            <Checkbox.Group options={depletionCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colDepletion')} />
          </Form.Item>
        }
      />
    </Fragment>
  );
};

export default HardwareCollapse;
