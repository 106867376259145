import { Form, FormInstance, Select, message } from 'antd';
import React, { useState } from 'react';
import { convertToApiFile, getUploadFileType, humanizeText } from '../../utils/global';
import AttachmentUploader from '../uploads/AttachmentUploader';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import './AddAttachmentForm.scss';
import { UploadAttachmentCategoryI } from '../../interfaces';
// import { UseMutateFunction } from 'react-query';
import { useParams } from 'react-router-dom';
import { validateAttachmentCategory } from '../../utils/image';

type Props = {
  isRequired?: boolean;
  multiple?: boolean;
  categories?: { value: string; name: string }[];
  handleCancel: () => void;
  update: (_: any) => void;
  form: FormInstance<any>;
};

const AddAttachmentForm: React.FC<Props> = ({ form, isRequired, multiple, categories, handleCancel, update }) => {
  const [documents, setDocuments] = useState([]);
  const [fileType, setFileType] = useState<any>();
  const { uuid, id, serialCode, code, sku } = useParams();
  const idParam = [uuid, id, serialCode, code, sku].find((param) => param) || '0';

  const onCancel = () => {
    setDocuments([]);
    handleCancel();
    form.resetFields();
  };

  const handleSubmit = () => {
    const apiFiles = documents.map((file: any) => {
      return { ...convertToApiFile(file), categoryName: form.getFieldValue('categoryName') };
    });
    update({ id: idParam, values: { attachments: apiFiles } });
    handleCancel();
    setDocuments([]);
    form.resetFields();
  };

  const onCategoryChange = (value: UploadAttachmentCategoryI) => {
    setFileType(getUploadFileType[value]);
    if (documents && documents.length > 0) {
      const isValid = validateAttachmentCategory(documents, value);
      if (!isValid) {
        form.resetFields(['categoryName']);
        message.error('Please select category correctly!');
      }
    }
  };

  return (
    <div className="upload-attachment-form-container form-container">
      <h2 className="form-title">Upload Attachment</h2>
      <Form form={form} onFinish={handleSubmit} scrollToFirstError autoComplete="off" layout={'vertical'}>
        {categories && (
          <div className="attachment-category-wrap">
            <p>Category*</p>
            <Form.Item name="categoryName" rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder="Select a Category" style={{ width: '100%' }} onChange={onCategoryChange}>
                {categories?.map((type: { name: string; value: string }) => (
                  <Select.Option key={type.value} value={type.value}>
                    <span className="text-capitalize">{humanizeText(type.name)}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}
        <AttachmentUploader
          name="file"
          documents={documents}
          setDocuments={setDocuments}
          multiple={multiple ?? false}
          maxCount={!multiple ? 2 : 100}
          acceptType={fileType}
          isRequired={isRequired}
        />
        <div className="actions">
          <SecondaryButton label="Cancel" onClick={onCancel} />
          <PrimaryButton label="Upload" htmlType="submit" />
        </div>
      </Form>
    </div>
  );
};

export default AddAttachmentForm;
