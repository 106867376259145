import React, { PropsWithChildren } from 'react';

import Text from '../Text';
import { Divider } from 'antd';

interface Props extends PropsWithChildren {
  title: string;
  className?: string;
}

const DetailsView: React.FC<Props> & { Field: typeof Field } = ({ title, children, className }) => {
  return (
    <section
      className={`tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-gap-8 tw-flex tw-flex-col tw-flex-1 ${className}`}
    >
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Text variant="h4" className="tw-text-primary-500 tw-capitalize">
          {title}
        </Text>

        <Divider className="tw-bg-primary-500 tw-m-0" />
      </div>
      {children}
    </section>
  );
};

const Field = (props: { label: string; value: React.ReactNode; className?: string }) => {
  return (
    <div className={`${props.className} tw-flex tw-flex-col tw-gap-2`}>
      <Text variant="inputLabelMd">{props.label}</Text>
      {typeof props.value !== 'object' || props.value === null ? (
        <Text variant="bodyLgB">
          {!props.value && typeof props.value !== 'number' ? 'Not Applicable' : props.value}
        </Text>
      ) : (
        <div>{props.value}</div>
      )}
    </div>
  );
};

DetailsView.Field = Field;

export default DetailsView;
