import React from 'react';
import './Information.scss';
import Divider from '../Divider';
import { HardwareDepletionI } from '../../interfaces/hardwareDepletion';
import { DateFormat, TimeFormat } from '../../utils/global';
import DetailsView from '../DetailsView';

type Props = {
  depletionHistory?: HardwareDepletionI;
};

const DepletionHistoryInformation: React.FC<Props> = ({ depletionHistory }) => {
  return (
    <div className="registration-history-information-container details-container">
      <DetailsView title="Deplete Information">
        <div className="flex-row">
          <DetailsView.Field label="Date" value={DateFormat(depletionHistory?.createdAt)} />
          <DetailsView.Field label="Time" value={TimeFormat(depletionHistory?.createdAt)} />
        </div>
        <Divider />
        <DetailsView.Field label="Number of ecoTOTES" value={depletionHistory?.successHardwareCount} />
        <Divider />
        <DetailsView.Field label="Remarks" value={depletionHistory?.remarks} />
      </DetailsView>
    </div>
  );
};

export default DepletionHistoryInformation;
