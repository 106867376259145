import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const FlagIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 12 12"
      height={`${height ?? 12}px`}
      viewBox="0 0 12 12"
      width={`${width ?? 12}px`}
      fill={color ?? 'currentColor'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.65 1.15C9.8 1 10 0.95 10.2 1.05C10.4 1.15 10.5 1.3 10.5 1.5V7.5C10.5 7.65 10.45 7.75 10.35 7.85C10.25 7.95 9.6 8.5 8 8.5C7.21686 8.5 6.56105 8.24534 5.95436 8.00975C5.90256 7.98964 5.85113 7.96966 5.8 7.95C5.68493 7.91164 5.57169 7.87144 5.45924 7.83152C4.98535 7.6633 4.52534 7.5 4 7.5C3.2 7.5 2.7 7.65 2.5 7.75V11C2.5 11.3 2.3 11.5 2 11.5C1.7 11.5 1.5 11.3 1.5 11V7.5V1.5C1.5 1.35 1.55 1.25 1.65 1.15C1.75 1.05 2.4 0.5 4 0.5C4.85 0.5 5.55 0.75 6.2 1.05C6.31507 1.08836 6.42831 1.12856 6.54076 1.16847C7.01465 1.3367 7.47466 1.5 8 1.5C9.2 1.5 9.65 1.15 9.65 1.15ZM8 7.5C8.8 7.5 9.3 7.35 9.5 7.25V2.3C9.15 2.4 8.65 2.5 8 2.5C7.15 2.5 6.45 2.25 5.8 1.95C5.68493 1.91164 5.57169 1.87144 5.45924 1.83152C4.98535 1.6633 4.52534 1.5 4 1.5C3.2 1.5 2.7 1.65 2.5 1.75V6.7C2.85 6.6 3.35 6.5 4 6.5C4.85 6.5 5.55 6.75 6.2 7.05C6.31507 7.08836 6.42831 7.12856 6.54076 7.16848C7.01465 7.3367 7.47466 7.5 8 7.5Z"
        fill={color ?? 'currentColor'}
      />
      <mask id="mask0_13604_55944" maskUnits="userSpaceOnUse" x="1" y="0" width="10" height="12">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.65 1.15C9.8 1 10 0.95 10.2 1.05C10.4 1.15 10.5 1.3 10.5 1.5V7.5C10.5 7.65 10.45 7.75 10.35 7.85C10.25 7.95 9.6 8.5 8 8.5C7.21686 8.5 6.56105 8.24534 5.95436 8.00975C5.90256 7.98964 5.85113 7.96966 5.8 7.95C5.68493 7.91164 5.57169 7.87144 5.45924 7.83152C4.98535 7.6633 4.52534 7.5 4 7.5C3.2 7.5 2.7 7.65 2.5 7.75V11C2.5 11.3 2.3 11.5 2 11.5C1.7 11.5 1.5 11.3 1.5 11V7.5V1.5C1.5 1.35 1.55 1.25 1.65 1.15C1.75 1.05 2.4 0.5 4 0.5C4.85 0.5 5.55 0.75 6.2 1.05C6.31507 1.08836 6.42831 1.12856 6.54076 1.16847C7.01465 1.3367 7.47466 1.5 8 1.5C9.2 1.5 9.65 1.15 9.65 1.15ZM8 7.5C8.8 7.5 9.3 7.35 9.5 7.25V2.3C9.15 2.4 8.65 2.5 8 2.5C7.15 2.5 6.45 2.25 5.8 1.95C5.68493 1.91164 5.57169 1.87144 5.45924 1.83152C4.98535 1.6633 4.52534 1.5 4 1.5C3.2 1.5 2.7 1.65 2.5 1.75V6.7C2.85 6.6 3.35 6.5 4 6.5C4.85 6.5 5.55 6.75 6.2 7.05C6.31507 7.08836 6.42831 7.12856 6.54076 7.16848C7.01465 7.3367 7.47466 7.5 8 7.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_13604_55944)">
        <rect width="12" height="12" fill="#437132" />
      </g>
    </svg>
  );
};

export default FlagIcon;
