import React from 'react';
import { Button, Divider, Dropdown, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../svgs/MoreActionIcon';
import { DateFormat } from '../../utils/global';
import { AccessControlI } from '../../interfaces';
import Text from '../Text';
import { IconName } from '../Icon/iconName';
import Icon from '../Icon';
import { ProductGroupI } from '../../interfaces/product';

type Props = {
  item: ProductGroupI;
  access: AccessControlI;
  isPlatform?: boolean;
};
const ProductGroupCardItem: React.FC<Props> = ({ item, access, isPlatform = false }) => {
  const navitate = useNavigate();

  const items = [
    access.isCanRead
      ? {
          key: '1',
          label: (
            <Link to={`/products/groups/${item.code}`}>
              <Button className="btn-table-action-primary" type="link">
                View
              </Button>
            </Link>
          ),
        }
      : null,
    access.isCanUpdate
      ? {
          key: '2',
          label: (
            <Link to={`/products/groups/${item.code}/edit`}>
              <Button className="btn-table-action-primary" type="link">
                Update
              </Button>
            </Link>
          ),
        }
      : null,
  ];

  const handleCardClick = (item: ProductGroupI) => {
    navitate(`/products/groups/${item.code}`);
  };

  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button
      className="hardware-card-item card-item tw-shadow-simple tw-p-4 tw-border-none"
      onClick={() => handleCardClick(item)}
    >
      {!isPlatform && (
        <a className="btn-more-action" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
          <Dropdown menu={{ items }}>
            <Space>
              <MoreActionIcon />
            </Space>
          </Dropdown>
        </a>
      )}
      <div className="tw-flex tw-flex-col tw-w-full">
        <Text variant="inputLabelSm" className="tw-text-grey-700 tw-text-left">
          Product Group
        </Text>
        <Text variant="bodyLgB" className="tw-text-primary-800 tw-text-left tw-mb-3">
          {item.title}
        </Text>
        <div className="tw-flex tw-flex-col tw-gap-2">
          {renderField({ icon: 'icon-receipt', value: `${item?.code}` })}
          {item?.products &&
            renderField({
              icon: 'icon-all_inbox',
              value: `${item?.products.length ?? 0} Products`,
            })}
        </div>
        <Divider className="tw-m-2" />
        {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
      </div>
    </button>
  );
};

export default ProductGroupCardItem;
