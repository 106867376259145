import React, { useState } from 'react';
import { HardwareI, HardwareUpdateStatusI } from '../../interfaces/hardware';
import { useOutletContext, useParams } from 'react-router-dom';
import Text from '../Text';
import Icon from '../Icon';
import { Form, MenuProps, Modal, message } from 'antd';
import { CommonStatus } from '../CommonStatus';
import DropdownApp from '../Dropdown';
import UpdateStatusForm from '../forms/UpdateStatus';
import { updateHardwares, updateHardwareStatus } from '../../apis/hardwares';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import { useMutation, useQueryClient } from 'react-query';
import BackLink from '../buttons/BackLink';
import { getTrackingTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';
import IconText from '../IconText';
import { ProductAttachmentCategories } from '../../constants';
import AddAttachmentForm from '../forms/AddAttachmentForm';

type Props = {
  data: HardwareI;
};

const TrackingTopInfo: React.FC<Props> = ({ data }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'tracking');
  const [isUpdateStatusVisible, setIsUpdateStatusVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState<boolean>(false);
  const [form] = Form.useForm();

  function handleCancelUploadAttachment() {
    setIsVisibleModalUpload(false);
    form.resetFields(['categoryName', 'file']);
  }

  const { serialCode } = useParams();
  const { mutate: updateHardware } = useMutation(updateHardwares, {
    onSuccess: async () => {
      message.info('Uploaded attachment!');
      queryClient.invalidateQueries({
        queryKey: [`hardwares/${serialCode}`],
      });
    },
    onError: () => {
      message.error('Adding attachment failed! Please try again');
    },
  });
  const handleCancel = () => {
    setIsUpdateStatusVisible(false);
  };

  const { mutate: updateStatus } = useMutation(updateHardwareStatus, {
    onSuccess: async () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const onUpdateStatusSubmit = async (values: HardwareUpdateStatusI) => {
    const newParams = { id: data.serialCode, params: values };
    updateStatus(newParams);
    setIsUpdateStatusVisible(false);
  };

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'update-status') setIsUpdateStatusVisible(true);
    if (e.key === 'upload-attachments') setIsVisibleModalUpload(true);
  };

  const items: MenuProps['items'] = [
    ...(access.isCanUpdateStatus
      ? [
          {
            key: 'update-status',
            label: (
              <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
                <Icon name="icon-radio_button_checked" size={16} />
                <span>Update Status</span>
              </Text>
            ),
          },
        ]
      : []),
    // {
    //   key: 'report-fault',
    //   label: (
    //     <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
    //       <Icon name="icon-error_outline" size={16} />
    //       <span>Report Fault</span>
    //     </Text>
    //   ),
    // },
    // {
    //   key: 'configuration',
    //   label: (
    //     <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
    //       <Icon name="icon-build_circle" size={16} />
    //       <span>Add a Configuration</span>
    //     </Text>
    //   ),
    // },
    ...(access.isCanUpdate
      ? [
          {
            key: 'upload-attachments',
            label: (
              <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
                <Icon name="icon-upload_file" size={16} />
                <span>Upload Attachments</span>
              </Text>
            ),
          },
        ]
      : []),
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <section className="tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-flex tw-flex-col tw-flex-1 tw-mb-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <BackLink />
        {!!items.length && <DropdownApp buttonLabel="Manage" menu={menuDropdownProps} size="large" type="primary" />}
      </div>
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-auto">
          <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-2 tw-items-center">
            <IconText
              icon={handleDisplayIconByTypeFromApi(data?.type, { color: '#191919', width: 20, height: 20 })}
              text={getTrackingTypeFromApi(data)}
            />
            <span className=" tw-text-grey-700">{` | `}</span>
            <Text variant="h2" className="tw-truncate xs:tw-max-w-[195px] tw-text-grey-800">
              {data?.serialCode}
            </Text>
          </div>
          <div className="tw-flex tw-gap-x-0.5">
            <CommonStatus status={data?.status} />
            <CommonStatus status={data?.condition} />
          </div>
        </div>
      </div>
      <Modal open={isUpdateStatusVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={412}>
        <UpdateStatusForm onCancel={handleCancel} handleSubmit={onUpdateStatusSubmit} currentStatus={data?.status} />
      </Modal>
      <Modal
        open={isVisibleModalUpload}
        onOk={handleCancelUploadAttachment}
        onCancel={handleCancelUploadAttachment}
        footer={null}
        width={560}
      >
        <AddAttachmentForm
          form={form}
          handleCancel={handleCancelUploadAttachment}
          multiple
          categories={ProductAttachmentCategories}
          update={updateHardware}
        />
      </Modal>
    </section>
  );
};

export default TrackingTopInfo;
